import React, { Component } from "react"
import { connect } from "react-redux"

import AuthProvider from "../utils/authProvider"
import App from "../components/App/App"

import {
	updateApiaryAccount,
	updateApiaryError,
	updateApiaryToken,
	updateSpringBrookAccount,
	updateSpringBrookError,
	updateSpringBrookToken,
	updateIcommsAccount,
	updateIcommsError,
	updateIcommsToken,
	updateSearchAccounts,
	updateSearchLots,
	updateSearchMeters,
	updateGraphToken,
	updateProfile,
	updateView,
	updateDynamicsToken,
	updateCityworksToken,
} from "../actions/updateActions"
import { getProfile } from "../actions/serviceActions"

class AppContainer extends Component {
	
	render() {
		return (
			<div>
				
					<App {...this.props} /> 
			</div>
		)
	}
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({
	updateApiaryAccount: (account) => {
		dispatch(updateApiaryAccount(account))
	},
	updateApiaryError: (error) => {
		dispatch(updateApiaryError(error))
	},
	getProfile: (user,token) => {
		dispatch(getProfile(user,token))
	},
	updateApiaryToken: (token) => {
		dispatch(updateApiaryToken(token))
	},
	updateSpringBrookToken: (token) => {
		dispatch(updateSpringBrookToken(token))
	},
	updateSpringBrookAccount: (account) => {
		dispatch(updateSpringBrookAccount(account))
	},
	updateSpringBrookError: (error) => {
		dispatch(updateSpringBrookError(error))
	},
	updateIcommsToken: (token) => {
		dispatch(updateIcommsToken(token))
	},
	updateIcommsAccount: (account) => {
		dispatch(updateIcommsAccount(account))
	},
	updateIcommsError: (error) => {
		dispatch(updateIcommsError(error))
	},
	updateSearchAccounts:(accounts)=>{
		dispatch(updateSearchAccounts(accounts))
	},
	updateSearchLots:(lots) => {
		dispatch(updateSearchLots(lots))
	},
	updateSearchMeters:(meters)=> {
		dispatch(updateSearchMeters(meters))
	},
	updateGraphToken:(token)=>{
		dispatch(updateGraphToken(token))
	},
	updateDynamicsToken:(token)=>{
		dispatch(updateDynamicsToken(token))
	},
	updateCityworksToken:(token)=>{
		dispatch(updateCityworksToken(token))
	},
	updateProfile: (payload) => {
		dispatch(updateProfile(payload))
	},
	updateView: (payload) => {
		dispatch(updateView(payload))
	},
})

// AppContainer is a container component wrapped by HOC AuthProvider
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AuthProvider(AppContainer))
