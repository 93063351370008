import React, { Component } from "react"
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Label, Input } from "reactstrap"

import $ from "jquery"
import "jquery-ui"
import "jquery-ui/ui/widgets/sortable"
import LoadingSpinner from "./LoadingSpinner"

class Analysis extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dashboardDetails:{},
			powerBi:true
		}
		this.getPowerBIApp = this.getPowerBIApp.bind(this);
		this.togglePowerBi=this.togglePowerBi.bind(this)
	}

	
	componentDidMount() {
		var expire = new Date(this.props.account.idTokenClaims.exp*1000);
		var curDate = new Date()
		if(expire<curDate){
			this.props.signOut()
		}
		(function () {
			$(".connectedSortable").sortable({
				placeholder: "sort-highlight",
				connectWith: ".connectedSortable",
				handle: ".card-header, .nav-tabs",
				forcePlaceholderSize: true,
				zIndex: 999999,
			})
			$(
				".connectedSortable .card-header, .connectedSortable .nav-tabs-custom"
			)

			$(".btn-minmax").on("click", function () {
				var clickObject = this
				if ($(clickObject).hasClass("btn-min")) {
					setTimeout(function () {
						// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
						// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
						$(clickObject)
							.parents()
							.eq(3)
							.removeClass(function (index, className) {
								return (
									className.match(/(^|\s)col-md-\S+/g) || []
								).join(" ")
							})
						$(clickObject).parents().eq(3).addClass("col-md-12")
					}, 500)
					$(clickObject).removeClass("btn-min")
					$(clickObject).addClass("btn-max")
				} else if ($(clickObject).hasClass("btn-max")) {
					// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(clickObject)
						.parents()
						.eq(3)
						.removeClass(function (index, className) {
							return (
								className.match(/(^|\s)col-md-\S+/g) || []
							).join(" ")
						})
					$(clickObject).parents().eq(3).addClass("col-md-12")
					$(clickObject).removeClass("btn-max")
					$(clickObject).addClass("btn-min")
				}
			})

			$(".btn-expand").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize < 12) {
					var newSize = currentSize + 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})

			$(".btn-compress").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize > 3) {
					var newSize = currentSize - 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})
		})()
	}
	getPowerBIApp(){
		fetch("https://api.powerbi.com/v1.0/ebba2929-765b-48f7-8c03-9b450ed099ba/apps/57991b2e-3795-4f16-b419-57e5d1450193/dashboards/feea6727-6f93-444b-9c14-ee82c606ea2b", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization:
					"Bearer " + this.props.auth.springBrookAuthAccessToken,
			},
		})
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				}else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				this.setState({ dashboardDetails: data })
			})

			.catch(console.log)
	}
	togglePowerBi(){
		this.setState({powerBi:!this.state.powerBi})
	}
	render() {
		return (
			<div>
				<div>
					
					<UncontrolledDropdown>
							<DropdownToggle className="btn-secondary">
								<i className='fas fa-tasks'></i>
							</DropdownToggle>
							<DropdownMenu className="dropdownDisplay">
								<DropdownItem onClick={e=>this.togglePowerBi()}>
									<Input type="checkbox" id="PowerBi"checked={this.state.powerBi} onClick={e=>this.togglePowerBi()} />{'     '}
									<Label for="PowerBi" check>
									{"  "}Power BI
									</Label>
								</DropdownItem>								
							</DropdownMenu>
						</UncontrolledDropdown> 
						<br/>
					</div>
				{this.state.powerBi ?

				<section className='content'>
					<div className='grid'>
						<div className='row connectedSortable'>
							<div className='col-md-12'>
								<div className='card collapsed-card card-tools'>
                        			<div className='card-header btn btn-tool btn-minmax btn-max'  
											data-card-widget='collapse'
											data-toggle='tooltip'
											data-placement='top'
											title='Collapse Item'>
										<h3 className='card-title'>
											<i className='fas fa-text-width'></i>
											Power BI
										</h3>
										<div className='card-tools'>
											{/* RESIZE BUTTONS NEED TO BE IN card-tools AREA OR LEVEL NEEDS TO BE ADJUSTED IN THE JAVASCRIPT  */}
											{/* <button
												type='button'
												className='btn btn-tool btn-compress'
												data-toggle='tooltip'
												data-placement='top'
												title='Reduce Size'
											>
												<i className='fas fa-search-minus'></i>
											</button>
											<button
												type='button'
												className='btn btn-tool btn-expand'
												data-toggle='tooltip'
												data-placement='top'
												title='Increase Size'
											>
												<i className='fas fa-search-plus'></i>
											</button> */}
											<button
												type='button'
												className='btn btn-tool btn-minmax btn-max'
												data-card-widget='collapse'
												data-toggle='tooltip'
												data-placement='top'
												title='Collapse Item'
											>
												<i className='fas fa-plus'></i>
											</button>
											<button
												type='button'
												className='btn btn-tool'
												data-card-widget='remove'
												data-toggle='tooltip'
												data-placement='top'
												title='Remove Item'
												onClick={e=>this.togglePowerBi()}
											>
												<i className='fas fa-times'></i>
											</button>
										</div>
									</div>
									{/* /.card-header */}

									<div className='card-body'>
									
										<a 	className="btn btn-primary" 
											target="_blank"
											rel="noopener noreferrer" 
											href="https://app.powerbi.com/Redirect?action=OpenApp&appId=be3b741c-af1f-4a0a-865d-86acca3b6d1c&ctid=ebba2929-765b-48f7-8c03-9b450ed099ba" >
											Billing Analytics
										</a>
									    &nbsp;&nbsp;
										<a 	className="btn btn-primary" 
											target="_blank"
											rel="noopener noreferrer" 
											href="https://app.powerbi.com/Redirect?action=OpenApp&appId=54f8fdf7-fd14-4d1d-9c23-edd5528e2c56&ctid=ebba2929-765b-48f7-8c03-9b450ed099ba" >
											Payroll Analytics 
										</a>
																			 
									</div>

									{/* /.card-body */}
								</div>
								{/* /.card */}
							</div>
							
						</div>
					</div>
				</section>
				: null}
			</div>
		)
	}
}
export default Analysis
