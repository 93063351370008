import React, { Component, Fragment } from "react"
import fetch from "isomorphic-fetch"
import * as urls from "../../utils/urlsConfig"
import $ from "jquery"
import { UncontrolledTooltip  }from "reactstrap"
import "jquery-ui/ui/widgets/sortable"
import WorkOrderForm from "../modals/WorkOrderForm"
import ServiceRequestForm from "../modals/ServiceRequestForm"

/**
 * displayes lot/address details
 * list of meters associated with a lot also gets displayed
 */

class LotCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			itemDetails: null,
			sbURL: urls.springbrook,
			returnrdResults: false,
			selectedItem: "",
			dataReturned: false,
		}
		this.displayCustomer = this.displayCustomer.bind(this)
	}

	componentDidMount() {
		this.getLot();
		(function () {
			$(".connectedSortable").sortable({
				placeholder: "sort-highlight",
				connectWith: ".connectedSortable",
				handle: ".card-header, .nav-tabs",
				forcePlaceholderSize: true,
				zIndex: 999999,
			})
			$(
				".connectedSortable .card-header, .connectedSortable .nav-tabs-custom"
			)

			$(".btn-minmax").on("click", function () {
				var clickObject = this
				if ($(clickObject).hasClass("btn-min")) {
					setTimeout(function () {
						// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
						// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
						$(clickObject)
							.parents()
							.eq(3)
							.removeClass(function (index, className) {
								return (
									className.match(/(^|\s)col-md-\S+/g) || []
								).join(" ")
							})
						$(clickObject).parents().eq(3).addClass("col-md-auto")
					}, 500)
					$(clickObject).removeClass("btn-min")
					$(clickObject).addClass("btn-max")
				} else if ($(clickObject).hasClass("btn-max")) {
					// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(clickObject)
						.parents()
						.eq(3)
						.removeClass(function (index, className) {
							return (
								className.match(/(^|\s)col-md-auto+/g) || []
							).join(" ")
						})
					$(clickObject).parents().eq(3).addClass("col-md-12")
					$(clickObject).removeClass("btn-max")
					$(clickObject).addClass("btn-min")
				}
			})

			$(".btn-expand").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize < 12) {
					var newSize = currentSize + 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})

			$(".btn-compress").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize > 1) {
					var newSize = currentSize - 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})
		})()
	}
	/**
	 * get lot detils based on lot index
	 */
    getLot() {
		fetch(
			this.state.sbURL +
				"Lot/ByIndex?lotIndex=" +
				this.props.lotIndex,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				} else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				} else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
			
				if (data === "No meter found.") {
					this.setState({
						dataReturned: false,
						returnrdResults: true,
					})
				} else {
					this.setState({
						itemDetails: data,
						returnrdResults: true,
						dataReturned: true,
					})
					
				}
			})
			.catch(console.log)
	}
	/**
	 * toggles display of owner details
	 */
	displayCustomer(){
		this.props.updateCustomerIndex(true,this.state.itemDetails[0].CustomerIndex, "Owner");
	}
    render(){
        return(
			<div>
			{this.state.itemDetails != null ? (
				<Fragment>
					<WorkOrderForm
						lot={this.state.itemDetails[0]} 
						{...this.props} 
						
						/>
					<ServiceRequestForm lot={this.state.itemDetails[0]} 
						{...this.props} />
				</Fragment>):null}
            <div className='col-md-12'>
				<div className='card collapsed-card card-tools'>
                    <div className='card-header btn btn-tool btn-minmax btn-max'  
						data-card-widget='collapse'
						data-toggle='tooltip'
						data-placement='top'
						title='Collapse Item'>
						<h3 className='card-title'>
							<i className='fas fa-text-width'></i>
							Address and Lot Details
						</h3>
						<div className='card-tools'>
							<button
                                type='button'
                                className='btn btn-tool btn-minmax btn-max'
                                data-card-widget='collapse'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Collapse Item'
                            >
                                <i className='fas fa-plus'></i>
                            </button>
                            <button
                                type='button'
                                className='btn btn-tool'
                                data-card-widget='remove'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Remove Item'
								onClick={e=>this.props.toggleLot(true,false)}
							>
								<i className='fas fa-times'></i>
							</button>
						</div>
					</div>
					{/* /.card-header */}
					{this.state.returnrdResults ? (
						<div className='card-body'>
							
								<button id="owner" className="bg-primary btn color-palette-set" onClick={(e)=>this.displayCustomer(e)}>
									<b>Owner Number:</b>{" "}
									{
									this.state
										.itemDetails[0]
										.CustomerIndex
								}
								</button>
								
							<p>
								<b>Address:</b>{" "}
								{
									this.state
										.itemDetails[0]
										.streetNumber
								}{" "}
								{
									this.state
										.itemDetails[0]
										.streetDirectional
								}{" "}
								{
									this.state
										.itemDetails[0]
										.streetName
								}
							</p>
							<p>
								<b>
									City, State,
									ZIP:
								</b>{" "}
								{
									this.state
										.itemDetails[0]
										.city
								}
								,{" "}
								{
									this.state
										.itemDetails[0]
										.state
								}
								,{" "}
								{
									this.state
										.itemDetails[0]
										.zip
								}
							</p>
							<p>
								<b>Lot Status:</b>{" "}
								{
									this.state
										.itemDetails[0]
										.lotStatus
								}
							</p>
							<p>
								<b>
									Parcel Number:
								</b>{" "}
								{
									this.state
										.itemDetails[0]
										.parcel
								}
							</p>
							<p>
								<b>Description:</b>{" "}
								{
									this.state
										.itemDetails[0]
										.description
								}
							</p>
						</div>
					) : (
						<div className='card-body'>
							<p>
								Waiting for details
							</p>
						</div>
					)}
					{/* /.card-body */}
				</div>
				{/* /.card */}
			</div>
			</div>
        )
    }
}
export default LotCard
