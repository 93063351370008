import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { Button,Container,Row,Col } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';
import * as urls from '../../utils/urlsConfig';
import $ from "jquery"
import "jquery-ui/ui/widgets/sortable"

import {WorkOrder} from '../classes/WorkOrder'

class SpringbrookAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          baseURL:urls.springbrook+'WorkOrder/ByStatus?status=in progress',
          loading:false, 
          springbrookworkOrders:[],
          fileName:'',
          fileHandle: new FormData(),
          reads:[]

        };
        
        this.toggleLoading = this.toggleLoading.bind(this);
        this.sendToCityworks = this.sendToCityworks.bind(this);
        this.getSB = this.getSB.bind(this);
        this.setCreators = this.setCreators.bind(this)
      }

    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    
    componentDidMount(){
       
        this.getSB();
        // this function is the js to move the cards arround
        (function () {
            
            $(".btn-minmax").on("click", function () {
                var clickObject = this
                if ($(clickObject).hasClass("btn-min")) {
                    setTimeout(function () {
                        // LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
                        // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                        $(clickObject)
                            .parents()
                            .eq(3)
                            .removeClass(function (index, className) {
                                return (
                                    className.match(/(^|\s)col-md-\S+/g) || []
                                ).join(" ")
                            })
                        $(clickObject).parents().eq(3).addClass("col-md-auto")
                    }, 500)
                    $(clickObject).removeClass("btn-min")
                    $(clickObject).addClass("btn-max")
                } else if ($(clickObject).hasClass("btn-max")) {
                    // LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
                    // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                    $(clickObject)
                        .parents()
                        .eq(3)
                        .removeClass(function (index, className) {
                            return (
                                className.match(/(^|\s)col-md-\S+/g) || []
                            ).join(" ")
                        })
                    $(clickObject).parents().eq(3).addClass("col-md-12")
                    $(clickObject).removeClass("btn-max")
                    $(clickObject).addClass("btn-min")
                }
            })
    
            $(".btn-expand").on("click", function () {
                // LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
                var currentClass = $(this).parents().eq(3).attr("class")
                var currentClassPart = ""
                $.each(currentClass.split(/\s/), function (_, cn) {
                    // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                    if (cn.indexOf("col-md") === 0) {
                        currentClassPart = cn
                        return false
                    }
                })
                var currentMatches = currentClassPart.match(/\d+$/)
                if (currentMatches) {
                    var currentSize = parseInt(currentMatches[0], 10)
                }
                if (currentSize < 12) {
                    var newSize = currentSize + 1
                    // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                    $(this)
                        .parents()
                        .eq(3)
                        .removeClass("col-md-" + currentSize)
                    $(this)
                        .parents()
                        .eq(3)
                        .addClass("col-md-" + newSize)
                }
            })
    
            $(".btn-compress").on("click", function () {
                // LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
                var currentClass = $(this).parents().eq(3).attr("class")
                var currentClassPart = ""
                $.each(currentClass.split(/\s/), function (_, cn) {
                    // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                    if (cn.indexOf("col-md") === 0) {
                        currentClassPart = cn
                        return false
                    }
                })
                var currentMatches = currentClassPart.match(/\d+$/)
                if (currentMatches) {
                    var currentSize = parseInt(currentMatches[0], 10)
                }
                if (currentSize > 12) {
                    var newSize = currentSize - 1
                    // CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
                    $(this)
                        .parents()
                        .eq(3)
                        .removeClass("col-md-" + currentSize)
                    $(this)
                        .parents()
                        .eq(3)
                        .addClass("col-md-" + newSize)
                }
            })
        })()
    }
    // get the workorders in springbrook
    getSB(){
        this.setState({loading:true})
        fetch(this.state.baseURL, {
            method:"GET",
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.props.springBrookToken
            }
        }).then(function(response) {
            if (response.ok) {
            return response
            } else if(response.status === 401){
                alert("Your token has expired. Please refresh the page.");
                this.setState({loading:false})
            } else {
            var error = new Error(response.statusText);
            error.response = response;
            //this.setState({loading:false});
            throw error;
            }
        })
        .then(res => res.json())
        .then((data) => {
            if(data.length===0){
                alert("There are no current work orders in progress returned from springbrook");
                this.setState({loading:false})
            }else{
                this.setState({springbrookworkOrders:[]});
                var tempdata=data;
                
                tempdata.map(val=>{
                    var wo=new WorkOrder(val)
                    this.state.springbrookworkOrders.push(wo);
                    return true
                });
                this.setState({ springbrookworkOrders:this.state.springbrookworkOrders});
            }
            this.setCreators()
        })
        .catch(console.log);
           
        return false
    }
    // get all employees from the cityworks interface to set correct creator 
    // data for springbrook workorders to get sent to cityworks
    setCreators(){
        fetch(
			urls.cityWorks +
				"Employee/All" ,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				} else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				//this.state.customers = data;
				for(var i=0;i<this.state.springbrookworkOrders.length;i++){
                    for(var j=0;j<data.length;j++){
                        if(this.state.springbrookworkOrders[i].getWO().creator === data[j].firstName[0].toLowerCase()+data[j].lastName.toLowerCase()){
                            this.state.springbrookworkOrders[i].setCreator(data[j].employeeSid.toString())
                        }
                    }
                    this.state.springbrookworkOrders[i].setNotes(this.state.springbrookworkOrders[i].getWO().notes.replace(/(\r\n|\n|\r)/gm,""));
                    this.state.springbrookworkOrders[i].setNotes(this.state.springbrookworkOrders[i].getWO().notes.replace(/(")/gm,"inch"));
                }
                this.setState({loading:false})
								
			})
			.catch(console.log)
    }
    // send all the springbrook WO to cityworks
    sendToCityworks(e){
        e.preventDefault();
        
       for(var i=0;i<this.state.springbrookworkOrders.length;i++){
        this.setState({loading:true})
        var bodyJSON=JSON.stringify(this.state.springbrookworkOrders[i].getWO())
        fetch(urls.cityWorks+"WorkOrder/Create",{
            method:"POST",
            headers:{ 'Content-Type': 'application/json'},
            body:bodyJSON
        }).then(function(response) {
            if (response.ok) {
                return response
            } else {
                var error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(res => res.json())
        .then((data) => {
           
                
                alert(data);
            
            })
           .catch(console.log);
        }
        this.setState({loading:false})
        
    }
    
    
   

    render(){
        
        
        return (
            <div>
                <div className='col-md-12'>
                    <div className='card collapsed-card card-tools'>
                        <div className='card-header btn btn-tool btn-minmax btn-max'  
                                    data-card-widget='collapse'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Collapse Item'>
                        <h3 className='card-title'>
                            <i className='fas fa-text-width'></i>
                            Springbrook tools
                        </h3>
                        <div className='card-tools'> 
                           
                            <button
                                type='button'
                                className='btn btn-tool btn-minmax btn-max'
                                data-card-widget='collapse'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Collapse Item'
                            >
                                <i className='fas fa-plus'></i>
                            </button>
                            <button
                                type='button'
                                className='btn btn-tool'
                                data-card-widget='remove'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Remove Item'
                                onClick={e=>this.props.toggleWater()}
                            >
                                <i className='fas fa-times'></i>
                            </button>
                        </div>
                    </div>
                    {/* /.card-header */}

                    <div className='card-body'>
                        <Container>
                            <Row>
                                <Col>
                                    {this.props.appToken ? (
                                            <div>

                                            <header >
                                                <h1>Work Orders</h1>
                                            </header>
                                            <div>
                                               
                                                    {
                                                        this.state.loading? <LoadingSpinner/> :
                                                        <div>
                                                        {this.state.springbrookworkOrders.length===0 ?
                                                            <LoadingSpinner/> :
                                                            <Button className="bg-orange btn color-palette-set" onClick={e=>this.sendToCityworks(e)}>Send SB WOs to Cityworks</Button>
                                                        }   </div >
                                                    }
                                                   
                                           
                                            </div>
                                        </div>
                                        
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {/* /.card-body */}
                </div>
                {/* /.card */}
                </div>
                </div>
           
        )
    }
}
export default SpringbrookAdmin;