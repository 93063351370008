import React, { Component } from "react"
import fetch from "isomorphic-fetch"
import * as urls from "../../utils/urlsConfig"
import { Form, Input } from "reactstrap"

/**
 * modal to let user submit an 
 * issue straight to taiga
 */

class Bugs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form: [],
			taigaURL: urls.taiga,
			description: String,
			severity: { name: "Normal", id: 2237789 },//1372182, //default normal
			severities: [
				{ name: "Wishlist", id: 2237787 }, //
				{ name: "Minor", id: 2237788}, //
				{ name: "Normal", id: 2237789 }, // 
				{ name: "Important", id: 2237790 }, // 
				{ name: "Critical", id: 2237791 }, // 
			],
			type: { name: "Bug", id: 1352298 },
			types: [
				{ name: "Bug", id: 1352298 },
				{ name: "Question", id: 1352299 },
				{ name: "Enhancement", id: 1352300 },
			],
			subject: String,
			notes: String,
			userStory: 0,
			taigaAuth: "",
			clientAzureToken: "",
			vaultToken: "",
			azureKeyStoreURL: urls.keystoreURL,
		}
		this.getTaigaKey = this.getTaigaKey.bind(this);
		this.sendToTaiga = this.sendToTaiga.bind(this);
		this.makeTaigaCall = this.makeTaigaCall.bind(this);
	}
	
	componentDidMount() {
		if(this.props.auth.apiaryAuthAccessToken){
		while(this.state.taigaAuth.length===0){
		this.getTaigaKey()
		}}
	}

	getTaigaKey() {
		if (this.props.auth.apiaryAuthAccessToken) {
			return fetch(this.state.azureKeyStoreURL + "/taiga", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.props.auth.apiaryAuthAccessToken,
				},
			})
				.then(function (response) {
					if (response.ok) {
						return response
					}  else if(response.status === 401){
						alert("Taiga key was not returned correctly.");
						this.setState({loading:false})
					}else {
						console.log("response error")
						var error = new Error(response.statusText)
						throw error
					}
				})
				.then((res) => res.text())
				.then((data) => {
					
					this.setState({ taigaAuth: data });
				})
				.catch(console.log)
		} else {
			console.log("got to wait for token")
		}
	}
	makeTaigaCall() {
		var bug = {
			assigned_to: null,
			blocked_note: null,
			description:
				"from " + this.state.notes + ": " + this.state.description,
			is_blocked: false,
			is_closed: false,
			milestone: null,
			priority: null,
			project: 447988,
			severity: this.state.severity.id,
			status: 3139346,
			subject: this.state.subject,
			tags: ["#" + this.state.userStory],
			type: this.state.type.id,
			watchers: [],
		}
		fetch(this.state.taigaURL + "issues", {
			//mode:"no-cors",
			method: "POST",
			body: JSON.stringify(bug),
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + this.state.taigaAuth,
			},
		})
			.then(function (response) {
				if (response.ok) {
					return response
				} else if(response.status === 401){
					alert("Tiaga token not retireved correctly.");
					this.setState({loading:false})
				} else {
					var error = new Error(response.statusText)
					throw error
					alert(error)
				}
			})
			.then((res) => res.json())
			.then((data) => {
				
				alert("Your issue was successfully submitted.")
				this.setState({ userStory: 0 })
				this.setState({ severity: 1372182 })
				this.setState({ subject: String })
				this.setState({ description: String })
				this.setState({ notes: String })
				this.setState({ type: { name: "Bug", id: 830024 } })
			})
			.catch(console.log)
	}

	sendToTaiga(e){
		e.preventDefault()
		
		if (this.state.taigaAuth.length === 0) {
			this.getTaigaKey()
				
				.then(() => this.makeTaigaCall())
				.catch(console.log)
		} else {
			this.makeTaigaCall()
		}
	}
	

	render() {
		return (
			<div className='modal fade' id='modal-bug' tabindex="-1" >
				<div className='modal-dialog'>
					<div className='modal-content'>
						<Form autoComplete="off"
							roll='form'
							className='bugForm'
							onSubmit={e=>this.sendToTaiga(e)}
							
						>
							<div className='modal-header'>
								<h4 className='modal-title'>
									Submit a bug report
								</h4>
								<button
									type='button'
									className='close'
									data-bs-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='row'>
									<div className='col-sm-12'>
										{/*text input */}
										<div className='form-group'>
											<label>Subject</label>
											<Input
												required
												id='sub'
												type='text'
												className='form-control'
												placeholder='Enter subject'
												value={this.state.subject}
												onChange={(e) =>
													this.setState({
														subject: e.target.value,
													})
												}
											></Input>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-sm-6'>
										{/* text input */}
										<div className='form-group'>
											<label>Name</label>
											<Input
												required
												id='name'
												type='text'
												className='form-control'
												placeholder='Enter your name'
												value={this.state.notes}
												onChange={(e) =>{
													this.setState({
														notes: e.target.value,
													}); this.getTaigaKey();}
												}
											></Input>
										</div>
									</div>
									
								</div>
								<div className='row'>
									<div className='col-sm-6'>
										{/* select */}
										<div className='form-group'>
											<label>Severity</label>
											<Input
												required
												id='sev'
												type='select'
												className='form-control'
												default="0"
												onChange={(e) =>{
													this.state.severity.id=e.target.value; 
													console.log(this.state.severity)}
												}
											>
												<option value="0">---</option>
												{this.state.severities.map(
													(sev) => (
														<option
															key={sev.id}
															value={sev.id}
														>
															{sev.name}
														</option>
													)
												)}
											</Input>
										</div>
									</div>
									<div className='col-sm-6'>
										<div className='form-group'>
											<label>Type</label>
											<Input
												required
												id='typ'
												type='select'
												className='form-control'
												onChange={(e) => {
													this.state.type.id =
														e.target.value
												}}
											>
												<option>---</option>
												{this.state.types.map((typ) => (
													<option
														key={typ.id}
														value={typ.id}
													>
														{typ.name}
													</option>
												))}
											</Input>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-sm-12'>
										{/* textarea */}
										<div className='form-group'>
											<label>Description</label>
											<Input
												required
												id='des'
												type='textarea'
												className='form-control'
												rows='5'
												placeholder='Enter a description of your problem'
												value={this.state.description}
												onChange={(e) =>
													this.setState({
														description:
															e.target.value,
													})
													
												}
											></Input>
										</div>
									</div>
								</div>
							</div>
							{/* end .modal-body */}

							<div className='modal-footer justify-content-between'>
								<Input type='submit' className='btn btn-info' />
								<button
									type='button'
									className='btn btn-default'
									data-bs-dismiss='modal'
								>
									Close
								</button>
							</div>
						</Form>
					</div>
					{/* end .modal-content */}
				</div>
				{/* end .modal-dialog */}
			</div>
			// end.modal
		)
	}
}
export default Bugs
