import React, { Component, Fragment } from "react"
import Metavante from'./Metavante';
import Fiserv from './Fiserv';
import Ipay from './Ipay';
import { Container, Col, Row } from "reactstrap"
import $ from "jquery"
import "jquery-ui/ui/widgets/sortable"
import LoadingSpinner from "../../LoadingSpinner"
class Payments extends Component {
    
    
    componentDidMount(){
        (function () {
			

			$(".btn-minmax").on("click", function () {
				var clickObject = this
				if ($(clickObject).hasClass("btn-min")) {
					setTimeout(function () {
						// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
						// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
						$(clickObject)
							.parents()
							.eq(3)
							.removeClass(function (index, className) {
								return (
									className.match(/(^|\s)col-md-\S+/g) || []
								).join(" ")
							})
						$(clickObject).parents().eq(3).addClass("col-md-12")
						$(clickObject).removeClass("btn-min")
					$(clickObject).addClass("btn-max")
					}, 500)
					
				} else if ($(clickObject).hasClass("btn-max")) {
					// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(clickObject)
						.parents()
						.eq(3)
						.removeClass(function (index, className) {
							return (
								className.match(/(^|\s)col-md-\S+/g) || []
							).join(" ")
						})
					$(clickObject).parents().eq(3).addClass("col-md-12")
					$(clickObject).removeClass("btn-max")
					$(clickObject).addClass("btn-min")
				}
			})

			$(".btn-expand").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize < 12) {
					var newSize = currentSize + 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})

			$(".btn-compress").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize > 12) {
					var newSize = currentSize - 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})
		})()
    }
    render(){
        return(
            <Fragment>
                <div className='col-md-12'>
                    <div className={'card card-tools '+this.props.profile.views.tools.paymentsExpand}>
                        <div className='card-header btn btn-tool btn-minmax btn-max'  
                                    data-card-widget='collapse'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Collapse Item'>
                        <h3 className='card-title'>
                            <i className='fas fa-text-width'></i>
                            Payments
                        </h3>
                        <div className='card-tools'>
                            {/* RESIZE BUTTONS NEED TO BE IN card-tools AREA OR LEVEL NEEDS TO BE ADJUSTED IN THE JAVASCRIPT  */}
                            {/* <button
                                type='button'
                                className='btn btn-tool btn-compress'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Reduce Size'
                            >
                                <i className='fas fa-search-minus'></i>
                            </button>
                            <button
                                type='button'
                                className='btn btn-tool btn-expand'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Increase Size'
                            >
                                <i className='fas fa-search-plus'></i>
                            </button> */}
                            <button
                                type='button'
                                className='btn btn-tool btn-minmax btn-max'
                                data-card-widget='collapse'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Collapse Item'
                            >
                                {this.props.profile.views.tools.paymentsExpand===''?
                                <i className='fas fa-minus'></i>:
                                <i className='fas fa-plus'></i>
                                
                            }
                                
                            </button>
                            <button
                                type='button'
                                className='btn btn-tool'
                                data-card-widget='remove'
                                data-toggle='tooltip'
                                data-placement='top'
                                title='Remove Item'
                                onClick={e=>this.props.togglePayments()}
                            >
                                <i className='fas fa-times'></i>
                            </button>
                        </div>
                    </div>
                    {/* /.card-header */}

                    <div className='card-body'>
                        <Container>
                            <Row>
                                {/* <Col>
                                    {this.props.icommsToken ? (
                                        <Metavante
                                            icommsToken={
                                                this.props
                                                    .icommsToken
                                            }
                                            springBrookToken={
                                                this.props
                                                    .springBrookToken
                                            }
                                            igraphToken={this.props.igraphToken}
                                            {...this.props}
                                        />
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </Col> */}
                                <Col>
                                    {this.props.icommsToken ? (
                                        <Fiserv
                                            icommsToken={
                                                this.props
                                                    .icommsToken
                                            }
                                            springBrookToken={
                                                this.props
                                                    .springBrookToken
                                            }
                                            igraphToken={this.props.igraphToken}
                                            {...this.props}
                                        />
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </Col>
                                <Col>
                                    {this.props.icommsToken ? (
                                        <Ipay
                                            icommsToken={
                                                this.props
                                                    .icommsToken
                                            }
                                            springBrookToken={
                                                this.props
                                                    .springBrookToken
                                            }
                                            igraphToken={this.props.igraphToken}
                                            {...this.props}
                                        />
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {/* /.card-body */}
                </div>
                {/* /.card */}
            </div>
            </Fragment>
        )
    }
                                                    
}
export default Payments;