export const updateApiaryAccount = (payload) => ({
    type: "UPDATE_APIARY_ACCOUNT",
    payload,
});

export const updateApiaryError = (payload) => ({
    type: "UPDATE_APIARY_ERROR",
    payload,
});

export const updateApiaryToken = (payload) => ({
    type: "UPDATE_APIARY_TOKEN",
    payload,
});

export const updateSpringBrookAccount = (payload) => ({
    type: "UPDATE_SPRINGBROOK_ACCOUNT",
    payload,
});

export const updateSpringBrookError = (payload) => ({
    type: "UPDATE_SPRINGBROOK_ERROR",
    payload,
});

export const updateSpringBrookToken = (payload) => ({
    type: "UPDATE_SPRINGBROOK_TOKEN",
    payload,
});

export const updateIcommsAccount = (payload) => ({
    type: "UPDATE_ICOMMS_ACCOUNT",
    payload,
});

export const updateIcommsError = (payload) => ({
    type: "UPDATE_ICOMMS_ERROR",
    payload,
});

export const updateIcommsToken = (payload) => ({
    type: "UPDATE_ICOMMS_TOKEN",
    payload,
});
export const updateGraphToken = (payload) => ({
    type: "UPDATE_GRAPH_TOKEN",
    payload,
});
export const updateProfile = (payload) => ({
    type: "UPDATE_PROFILE",
    payload,
});
export const updateView = (payload) => ({
    type: "UPDATE_VIEW",
    payload,
});

export const updateUI = (payload) => ({
    type: "UPDATE_UI",
    payload,
});

export const updateSearchMeters = (payload) => ({
    type: "UPDATE_METERS",
    payload,
});

export const updateSearchAccounts = (payload) => ({
    type: "UPDATE_ACCOUNTS",
    payload,
});

export const updateSearchLots = (payload) => ({
    type: "UPDATE_LOTS",
    payload,
});
export const updateDynamicsToken = (payload) => ({
    type: "UPDATE_DYNAMICS_TOKEN",
    payload,
});
export const updateCityworksToken = (payload) => ({
    type: "UPDATE_CITYWORKS_TOKEN",
    payload,
});