import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import "bootstrap-social/bootstrap-social.css"
import * as serviceWorker from "./serviceWorker"

import { Provider } from "react-redux"
import configureStore from "./store"

import AppContainer from "./containers/AppContainer"

ReactDOM.render(
	<Provider store={configureStore()}>
		<AppContainer />
	</Provider>,
	document.getElementById("root")
)

serviceWorker.unregister()
