import React, { Component } from "react"
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom"
import Header from "../Header"
import SideBar from "../SideBar"
import BugsModal from "../modals/BugsModal"
import ProfileModal from "../modals/ProfileModal"
import Home from "../HomeSearch/Home"
import Analysis from "../analysis"
import UISamples from "../UISamples"
import Tools from "../tools/tools"
import Reports from "../reports/reports"
import ResizeSandbox from "../Resize"
import LoadingSpinner from "../LoadingSpinner"
import Admin from "../AdminTools/admin"



class Main extends Component {
	constructor(props) {
        super(props);
        this.state = {
			clickedHome:false,
		}
	}
	componentDidMount() {
		if(this.props.auth.apiaryAuthAccount != null && this.props.auth.apiaryAuthAccount.idTokenClaims.exp*1000 <= Date.now()){
			this.props.isAuthenticated = false
		}
	}

    updateClickEvent(){
		console.log("home click event registered")
		this.setState({clickedHome:!this.state.clickedHome})
     
	}

	render() {
		return (
			
			<BrowserRouter forceRefresh={false}>
				{this.props.auth.apiaryAuthAccessToken?
					<Header {...this.props} />
					:<LoadingSpinner/>}
				<SideBar {...this.props}  updateClickEvent={this.updateClickEvent}/>
	
				{/* Content Wrapper. Contains page content */}
				<div className='content-wrapper'>
					{/* Content Header (Page header) */}
					<section className='content-header'>
						<div className='container-fluid'>
							<div className='row mb-2'>
								<div className='col-sm-12'></div>
							</div>
						</div>
						{/* end .container-fluid */}
						<BugsModal {...this.props} />
						{this.props.auth.apiaryAuthAccessToken ? (
						<ProfileModal {...this.props} />):null}
						
					</section>
					{/* Main content */}
					{this.props.profile.views!=null?
					<section className='content'>
						<div className='container-fluid'>
							
								<Switch>
									<Route
										path='/resize'
										component={() => (
											<ResizeSandbox {...this.props} />
										)}
									/>
									<Route
										path='/samples'
										component={() => (
											<UISamples {...this.props} />
										)}
									/>
									<Route
										path='/analysis'
										component={() => (
											<Analysis {...this.props} />
										)}
									/>
									<Route
										path='/tools'
										component={() => (
											<Tools {...this.props} />
										)}
									></Route>
									<Route
										path='/reports'
										component={() => (
											<Reports {...this.props} />
										)}
									></Route>
									<Route
										path='/admin'
										component={() => (
											<Admin {...this.props} />
										)}
									></Route>
									<Route
										path='/'
										component={() => (
											<Home {...this.props} updateClickEvent={this.updateClickEvent} clickedHome={this.state.clickedHome}/>
										)}
									></Route>
								</Switch>
							
						</div>
					</section>:<LoadingSpinner/>}
					{/* end .content*/}
				</div>
				{/* end .content-wrapper */}
				</BrowserRouter>
		)
	}
}

export default withRouter(Main)
