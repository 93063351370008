import React, { Component } from "react"
import { Link} from "react-router-dom";

export default class SideBar extends Component {
	render() {
		return (
			// Main Sidebar Container
			<aside className='main-sidebar sidebar-dark-lightblue elevation-4 sidebar-no-expand'>
				{/* Brand Logo */}
				<a href='/' className='brand-link elevation-4 navbar-primary'>
					<img
						src='/img/ApiaryLogo.png'
						alt='Apiary Logo'
						className='brand-image img-circle elevation-3'
						style={{ opacity: 0.8 }}
					/>
					<span className='brand-text font-weight-light'>Apiary</span>
				</a>

				{/* Sidebar */}
				<div className='sidebar'>
					{/* Sidebar Menu */}
					<nav className='mt-4'>
						<ul
							className='nav nav-pills nav-sidebar flex-column'
							data-widget='treeview'
							role='menu'
							data-accordion='false'
						>
							{/* Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library */}
					
							<li className='nav-item has-treeview'>
								<Link to='/' className='nav-link'>
									<i className='nav-icon fas fa-home'></i>
									<p>Home</p>
								</Link>
							</li>
							<li className='nav-item'>
								<Link to='/tools' className='nav-link'>
									<i className='nav-icon fas fa-wrench'></i>
									<p>Tools</p>
								</Link>
							</li>
							<li className='nav-item has-treeview'>
								<Link to='/reports' className='nav-link'>
									<i className='nav-icon fas fa-print'></i>
									<p>Reports</p>
								</Link>
							</li>

							<li className='nav-item has-treeview'>
								<Link to='/analysis' className='nav-link'>
									<i className='nav-icon fas fa-chart-line'></i>
									<p>Analysis</p>
								</Link>
							</li>
							{this.props.profile.views===0?
							<li className='nav-item has-treeview'>
								<Link to='/admin' className='nav-link'>
									<i className="nav-icon fas fa-user-cog"></i>
									<p>Admin</p>
								</Link>
							</li>
							:null}
					
						</ul>
					</nav>
					{/* /.sidebar-menu */}
				</div>
				{/* /.sidebar */}
			</aside>
		)
	}
}
