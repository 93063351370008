import React from "react"
import PropTypes from "prop-types"
import { BrowserRouter } from "react-router-dom"

import { Button } from "react-bootstrap"
import MainContainer from "../../containers/MainContainer"
import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-free"
import "./App.css"

class App extends React.Component {

	componentDidMount() {
	}
	handleSignIn = () => {
		this.props.signIn().then(() => {
			if (this.props.account) {
				this.props.updateApiaryAccount(this.props.account)
			} else {
				if (this.props.error) {
					this.props.updateApiaryError(this.props.error)
				} else {
					this.props.updateApiaryError({
						errorMessage: "Sign-in failed. Please try again.",
					})
				}
			}
		})
	}

	handleSignOut = () => {
		this.props.signOut().then(() => {
			if (!this.props.account) {
				this.props.updateApiaryAccount(null)
			} else {
				if (this.props.error) {
					this.props.updateApiaryError(this.props.error)
				} else {
					this.props.updateApiaryError({
						errorMessage: "Sign-out failed. Please try again.",
					})
				}
			}
		})
	}

	render() {
		
		return (
			<div>
				{this.props.isAuthenticated  ? (
					<BrowserRouter>
						<div className='App'>
							<MainContainer
								{...this.props}
								acquireApiaryToken={
									this.props.acquireApiaryToken
								}
								updateApiaryToken={this.props.updateApiaryToken}
								acquireIcommsToken={
									this.props.acquireIcommsToken
								}
								updateIcommsToken={this.props.updateIcommsToken}
								acquireSpringBrookToken={
									this.props.acquireSpringBrookToken
								}
								acquireDynamicsToken={
									this.props.acquireDynamicsToken
								}acquireGraphToken={
									this.props.acquireGraphToken
								}
								acquireCityworksToken={this.props.acquireCityworksToken}
								updateDynamicsToken={this.props.updateDynamicsToken}
								updateCityworksToken={this.props.updateCityworksToken}
								updateGraphToken={this.props.updateGraphToken}
								updateSpringBrookToken={
									this.props.updateSpringBrookToken
								}
								updateSearchAccounts={this.props.updateSearchAccounts}
								updateSearchMeters={this.props.updateSearchMeters}
								updateSearchLots={this.props.updateSearchLots}
								updateProfile={this.props.updateProfile}
								updateView={this.props.updateView}
							/>
						</div>
					</BrowserRouter>
				) : (
					<div
						className='d-flex justify-content-center'
						style={{ height: "100vh", background: "#d7d8da" }}
					>
						<div
							style={{
								margin: 0,
								top: "40%",
								position: "absolute",
							}}
						>
							<h2>
								Welcome to Apiary. Please login to use your Vera
								Water and Power tools.
							</h2>
							<Button
								style={{ left: "50%", position: "absolute" }}
								size='lg'
								className='bg-info'
								onClick={this.handleSignIn}
							>
								Login
							</Button>
						</div>
					</div>
				)}
			</div>
		)
	}
}

App.propTypes = {
	account: PropTypes.object,
	error: PropTypes.string,
	signIn: PropTypes.func.isRequired,
	signOut: PropTypes.func.isRequired,
	acquireApiaryToken: PropTypes.func.isRequired,
	acquireIGraphToken: PropTypes.func.isRequired,
	acquireIcommsToken: PropTypes.func.isRequired,
	acquireSpringBrookToken: PropTypes.func.isRequired,
	acquireDynamicsToken: PropTypes.func.isRequired,
	acquireCityworksToken: PropTypes.func.isRequired,
}

export default App
