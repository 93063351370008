import React, { Component } from "react"
import LoadingSpinner from "./LoadingSpinner"


export default class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentUser: {},
		}
	}
	componentDidMount() {
		var temp
		this.setState({ currentUser: temp })
	}
	render() {
		return (
			/* Navbar */
			<nav className='main-header navbar navbar-expand navbar-dark navbar-gray-dark'>
				{/* Left navbar links */}
				<ul className='navbar-nav'>
					<li className='nav-item'>
						<a
							className='nav-link'
							data-widget='pushmenu'
							href='#'
							role='button'
						>
							<i className='fas fa-bars'></i>
						</a>
					</li>
					
				</ul>

				{/* Right navbar links*/}
				
				<ul className='navbar-nav ml-auto'>
					
				{this.props.auth.apiaryAuthAccessToken && this.props.search.meters!=null ? (
					<li className='nav-item'>
						<a
							className='nav-link btn bg-primary'
							data-bs-toggle='modal'
							data-bs-target='#modal-bug'
						>
							<i className='fas fa-bug'></i> Report Issues
						</a>
					</li> ): (
						<li className='nav-item'>
						<a
							className='nav-link btn bg-primary'
							
						>
							<LoadingSpinner />
						</a>
					</li>
						)}
					{/* Notifications Dropdown Menu */}
					{/* USER SETTINGS ARE HARD CODED AND NEED REPLACED WITH CORRECT USER DATA */}
					{this.props.auth.apiaryAuthAccount.account!=null ? (
						<li className='nav-item dropdown'>
							<a
								className='nav-link'
								data-bs-toggle='modal'
								data-bs-target='#modal-profile'
							>
								<i className='far fa-user'></i>
								&nbsp;&nbsp;
								{this.props.auth.apiaryAuthAccount.account.name}
							</a>
						</li>
					) : <LoadingSpinner />}
				</ul>
			</nav>
			/* end.navbar */
		)
	}
}
