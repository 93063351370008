import React, { Component, Fragment} from 'react';
import fetch from 'isomorphic-fetch';
import {  Form, Button, Label, Input, Container, Col, Row } from 'reactstrap';
import LoadingSpinner from '../../LoadingSpinner';
import * as urls from '../../../utils/urlsConfig';
import * as fileSaver from 'file-saver';
import $ from "jquery"
import "jquery-ui/ui/widgets/sortable"
import { number } from 'prop-types';


class ElectricReads extends Component {
    constructor(props) {
        super(props);
        this.state = {
          directoryURL:urls.icommsURL +"Sftp/Directory?servicename=CommandCenter",
          loading:false,
          uploaded:[],
          fileName:'',
          meterReadingURL:urls.icommsURL+'Sftp/MeterReading?servicename=commandcenter&fileName=',
          keyVaultURL:urls.keystoreURL,
          billingCyles:[],
          selectedCycle:'0',
          CCMeters:[],
          availableFiles:[],
          selectedFile:number,
          CCMetersFirst:[],
          meterIndexListFirst:[],
          CCMetersSecond:[],
          meterIndexListSecond:[],
          loadingDaily:false,
          loadingMaster:false,
          masterMeters:[],
          dateStr:''
        };
        this.toggleLoading = this.toggleLoading.bind(this);
        this.submitElectricFile = this.submitElectricFile.bind(this);
        this.getAvailableFiles=this.getAvailableFiles.bind(this);
        this.handleCyleSelection = this.handleCyleSelection.bind(this);
        this.getCCmeters=this.getCCmeters.bind(this);
        this.writeCCmeters=this.writeCCmeters.bind(this);
        this.getMasterMeters=this.getMasterMeters.bind(this);
        this.writeMasterMeters=this.writeMasterMeters.bind(this);
        this.downloadRawData = this.downloadRawData.bind(this);
      }

    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    
    componentDidMount(){
        var today=new Date();
       this.setState({dateStr:today.toDateString().replace(/\s+/g,'').replace(/\,/g,'')});
        (function () {
			
			$(".btn-minmax").on("click", function () {
				var clickObject = this
				if ($(clickObject).hasClass("btn-min")) {
					setTimeout(function () {
						// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
						// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
						$(clickObject)
							.parents()
							.eq(3)
							.removeClass(function (index, className) {
								return (
									className.match(/(^|\s)col-md-\S+/g) || []
								).join(" ")
							})
						$(clickObject).parents().eq(3).addClass("col-md-12")
						$(clickObject).removeClass("btn-min")
					$(clickObject).addClass("btn-max")
					}, 500)
					
				} else if ($(clickObject).hasClass("btn-max")) {
					// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(clickObject)
						.parents()
						.eq(3)
						.removeClass(function (index, className) {
							return (
								className.match(/(^|\s)col-md-\S+/g) || []
							).join(" ")
						})
					$(clickObject).parents().eq(3).addClass("col-md-12")
					$(clickObject).removeClass("btn-max")
					$(clickObject).addClass("btn-min")
				}
			})

			$(".btn-expand").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize < 12) {
					var newSize = currentSize + 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})

			$(".btn-compress").on("click", function () {
				// LEVEL "3" IS CURENTLY SET FOR PARENT CALCULATIONS - CHANGE IF RESIZE BUTTONS ARE PLACED IN A DIFFERENT POSITION
				var currentClass = $(this).parents().eq(3).attr("class")
				var currentClassPart = ""
				$.each(currentClass.split(/\s/), function (_, cn) {
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					if (cn.indexOf("col-md") === 0) {
						currentClassPart = cn
						return false
					}
				})
				var currentMatches = currentClassPart.match(/\d+$/)
				if (currentMatches) {
					var currentSize = parseInt(currentMatches[0], 10)
				}
				if (currentSize > 12) {
					var newSize = currentSize - 1
					// CURRENTLY SET TO MOBILE COLUMN BREAK 'MD'
					$(this)
						.parents()
						.eq(3)
						.removeClass("col-md-" + currentSize)
					$(this)
						.parents()
						.eq(3)
						.addClass("col-md-" + newSize)
				}
			})
		})()
        this.getAvailableFiles();
    }
    getAvailableFiles(){
        this.setState({loading:true})
        fetch(
			this.state.directoryURL,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + this.props.auth.icommsAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
                    alert("Your token has expired. Please refresh the page.");
                    this.setState({loading:false})
                }
                else if(response.status === 502){
                    alert("Command Center is not currently available. Please check back later.");
                    var error = new Error(response.statusText)
					throw error
                }else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				console.log(data)
                //data = data.slice(-32,)
                var tempFiles=[]
                for(var i = 0;i<data.length;i++){
                    if(!data[i].isDirectory){
                        tempFiles.push(data[i])
                    }
                }
                console.log(tempFiles)
                tempFiles.sort((a,b)=>{return a.modifyDate-b.modifyDate})
                for(var i=tempFiles.length;i>0;i--){
                    this.state.availableFiles.push(tempFiles[i-1])
                }
                console.log(this.state.availableFiles)

				this.setState({loading:false})
								
			})
			.catch(e=>{
                console.log(e);
                this.setState({loading:false})
            })
    }
  
   
    submitElectricFile(e){
        e.preventDefault();
        this.setState({loading:true})
        fetch(this.state.meterReadingURL+this.state.availableFiles[this.state.selectedFile].remoteFileName, {
            method:"GET",
            headers:{
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+this.props.icommsToken
            }
        }).then(function(response) {
            if (response.ok) {
                return response;
            } else {
                var error = new Error(response.statusText);
                error.response = response;
                this.setState({loading:false})
                alert("something went wrong from ICOMMS Command Center");
                throw error;
            }
        })
        .then(res =>{return res.blob() })
        .then(data=> {
            this.setState({uploaded:data})
            if(data.length===0){
                alert("download failed");
            }else{
               this.downloadRawData(data)
            }                       
            })
            .catch(error=>{
                console.log(error);
                alert("There was a problem please check back later");
                this.setState({loading:false});
            });
            this.setState({loading:false});  
    }

    downloadRawData(fileData){
        if(fileData.length===0){
            alert("download failed");
        }else{
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //res.download(fileName);  
            }
            else {
                try{
                    // var towrite = ["MapID,Merchant Name,Customer Account Number,Customer Name,Customer_Address,Customer Address2,Customer City,Customer_State,Customer Zip Code,Payment Description,Payment Amount,Payment Date,Return Reason Code,Return_Date,Adjustment Amount,Original Payment Date"]
                    // for(var i=0;i<fileData.length;i++){
                    //     var datestr=fileData[i].transactionDate.replace(/\//g,'');
                    //         towrite.push(fileData[i]);

                    // }
                    var a = new Blob([fileData],{type:"text/csv;charset=utf-8"});
                    //a.write(res);
                    var rawFileName=""+this.state.dateStr+this.state.availableFiles[this.state.selectedFile].remoteFileName+"";
                    fileSaver.saveAs(a,rawFileName);
                    // this.state.sharepointFile.push(towrite)
                    // this.saveFile()
                    this.setState({loading:false});
                }
                catch(error){
                    console.log(error);
                    //res.download(fileName);
                }
            }
        }
    }
    handleCyleSelection = (e) => {
		this.setState({ selectedFile: e.target.value})
	}
    getCCmeters(e){
        e.preventDefault();
       this.setState({loadingDaily:true,CCMetersFirst:[],CCMetersSecond:[],CCMeters:[]})
        fetch(this.state.sbURL+"CommandCenter", {
            method:"GET",
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.props.springBrookToken
            },
        }).then(function(response) {
            if (response.ok) {
                return response
            } else {
                var error = new Error(response.statusText);
                error.response = response;
                alert("Something went wrong from the get for SB Meter/CommandCenter " + response.ok);
                throw error;
            }
        })
        .then(res=>res.json())
        .then(data=> {
            for(var i=0;i<data.length;i++){
                this.state.CCMetersFirst.push(data[i])
            }
           
            }).then(()=>{
                    this.writeCCmeters();
                }).catch(console.log);
            
    }
    writeCCmeters(){
        
        this.setState({loadingDaily:true});
        var today = new Date()
                if(this.state.CCMetersFirst.length===0){
                    //this.setState({loading:false});
                    alert("No data returned meters ");
                }else{
                    //
    
                    //alert("The metavnte file containing data for "+data.length+"customers was successfully uploaded");
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        //res.download(fileName);  
                     }
                    else {
                        try{
                            var towrite = []
                            towrite.push("METER NUMBER,SERIAL NUMBER,SERVICE LOCATION,ACCOUNT NUMBER,CUSTOMER ID,EA LOCATION,BILLING CYCLE,SEAL NUMBER 1,SEAL NUMBER 2,"+
                            "POLE NUMBER,MULTIPLIER,DEMAND MULTIPLIER,REVENUE CLASS,LINE SECTION, GRID LOCATION, FEEDER,CUSTOM1,CUSTOM2,METER POSITION,SERVICE ADDRESS,"+
                            "SERVICE CITY,SERVICE STATE,SERVICE ZIP, SERVICE LATITUDE,SERVICE LONGITUDE,CUSTOMER LAST NAME\n")
                            for(var i=0;i<this.state.CCMetersFirst.length;i++){
                                var customerName=this.state.CCMetersFirst[i].CustomerName
                                if(customerName!==null){customerName=customerName.replace(/\,/g,' ');}
                                towrite.push(this.state.CCMetersFirst[i].MeterIndex+","+this.state.CCMetersFirst[i].MeterIndex+","+this.state.CCMetersFirst[i].Location+","+this.state.CCMetersFirst[i].AccountIndex+","+this.state.CCMetersFirst[i].CustomerIndex+",,"+this.state.CCMetersFirst[i].BillingCycle+",,,,,,,,,,"+this.state.CCMetersFirst[i].ReadingIndex+",,,"
                                +this.state.CCMetersFirst[i].Address+","+this.state.CCMetersFirst[i].City+","+this.state.CCMetersFirst[i].State+","+this.state.CCMetersFirst[i].Zip+
                                ",47.64817,-117.234,"+customerName+"\n");
                             }//for(var i=0;i<this.state.CCMetersSecond.length;i++){
                            //     towrite.push(this.state.CCMetersSecond[i].MeterIndex+",,"+this.state.CCMetersSecond[i].Location+
                            //     ","+this.state.CCMetersSecond[i].AccountIndex+","+this.state.CCMetersSecond[i].CustomerIndex+",,"+this.state.CCMetersSecond[i].BillingCycle+",,,,,,,,,,"+this.state.CCMetersSecond[i].ReadingIndex+",,,"
                            //     +this.state.CCMetersSecond[i].Address+","+this.state.CCMetersSecond[i].City+","+this.state.CCMetersSecond[i].State+","+this.state.CCMetersSecond[i].Zip+
                            //     ",,,"+this.state.CCMetersSecond[i].CustomerName+"\n");
                            // }
                            var a = new Blob(towrite,{type:"text/csv;charset=utf-8"});
                            //a.write(res);
                            fileSaver.saveAs(a,"CCDaily"+this.state.dateStr+".csv");
                        }
                        catch(error){
                            console.log(error);
                            //res.download(fileName);
                        }
                    }
                
                }
                this.setState({loadingDaily:false});
            
            
        
    }
    getMasterMeters(e){
        e.preventDefault();
       this.setState({loadingMaster:true,masterMeters:[]})
        fetch(this.state.sbURL+"MasterMeter", {
            method:"GET",
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.props.springBrookToken
            },
        }).then(function(response) {
            if (response.ok) {
                return response
            }  else if(response.status === 401){
                alert("Your springbrook token has expired. Please refresh the page.");
                this.setState({loading:false})
            }else {
                var error = new Error(response.statusText);
                error.response = response;
                alert("Something went wrong from the get for SB Meter/CommandCenter " + response.ok);
                throw error;
            }
        })
        .then(res=>res.json())
        .then(data=> {
            for(var i=0;i<data.length;i++){
                this.state.masterMeters.push(data[i])
            }

            }).then(()=>{
                    this.writeMasterMeters();
                }).catch(console.log);
            
    }
    writeMasterMeters(){
        
        this.setState({loadingMaster:true});
        var today = new Date()
                if(this.state.masterMeters.length===0){
                    //this.setState({loading:false});
                    alert("No data returned meters ");
                }else{
                    //
    
                    //alert("The metavnte file containing data for "+data.length+"customers was successfully uploaded");
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        //res.download(fileName);  
                     }
                    else {
                        try{
                            var towrite = []
                            for(var i=0;i<this.state.masterMeters.length;i++){
                                var regId;
                                if(this.state.masterMeters[i].RegisterId == null) {
                                   regId=""   
                                }else{
                                    regId=this.state.masterMeters[i].RegisterId
                                }
                                var accountNum=this.state.masterMeters[i].AccountIndex.replace(/(-)/gm,"");
                                towrite.push(this.state.masterMeters[i].MeterIndex+","+this.state.masterMeters[i].Address+",,,,"+accountNum+","
                                +this.state.masterMeters[i].CustomerName+","+this.state.masterMeters[i].Location+",,,"+regId+","+this.state.masterMeters[i].ReadingIndex+"\n");
                             }
                            var a = new Blob(towrite,{type:"text/csv;charset=utf-8"});
                            //a.write(res);
                            fileSaver.saveAs(a,"MasterMeter"+this.state.dateStr+".csv");
                        }
                        catch(error){
                            console.log(error);
                            //res.download(fileName);
                        }
                    }
                
                }
                this.setState({loadingMaster:false});
            
            
        
    }
    render(){
        var cycleOptions=this.state.availableFiles.map((file,index)=>{
           
            return(<option key={file.modifyDate} 
                    value={index}
                    >
                        {file.remoteFileName}
            </option>)
        })
        return (
            <Fragment>
                <div className='col-md-12'>
                    <div className={'card card-tools '+this.props.profile.views.tools.electricExpand}>
                        <div className='card-header btn btn-tool btn-minmax btn-max'  
                                    data-card-widget='collapse'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Collapse Item'>
                            <h3 className='card-title'>
                                <i className='fas fa-text-width'></i>
                                Electric Meter Reads
                            </h3>
                            <div className='card-tools'>
                                
                                <button
                                    type='button'
                                    className='btn btn-tool btn-minmax btn-max'
                                    data-card-widget='collapse'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Collapse Item'
                                >
                                    {this.props.profile.views.tools.electricExpand===''?
                                        <i className='fas fa-minus'></i>:
                                        <i className='fas fa-plus'></i>
                                        
                                    }
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-tool'
                                    data-card-widget='remove'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Remove Item'
                                    onClick={e=>this.props.toggleElectric()}
                                >
                                    <i className='fas fa-times'></i>
                                </button>
                            </div>
                        </div>
                        {/* /.card-header */}

                        <div className='card-body'>
                            <Container>
                                <Row>
                                    <Col>
                                        {this.props.appToken ? (
                                            <div>
                                                <header >
                                                    <h3>Electric Reads</h3>
                                                </header>
                                                <div>{
                                                    this.state.loading? <LoadingSpinner/> :
                                                    <Form >
                                                        <Label for="exampleSelect">Billing Cycle</Label>
                                                        <Input  type="select" 
                                                                name="select" 
                                                                id="exampleSelect" 
                                                                value={this.state.selectedFile.remoteFileName} 
                                                                required 
                                                                onChange={e=>this.handleCyleSelection(e)}
                                                                style={{"width":"43%","margin-bottom":"5px"}}>
                                                            <option value='0'> ------ </option>
                                                            {cycleOptions}
                                                        </Input> 
                                                        <Button type="submit" 
                                                        onClick={e=>{if(this.state.selectedFile) {this.submitElectricFile(e); this.setState({loading:true})}else{ e.preventDefault();alert("billing cycle not selected")}}}>Submit</Button>
                                                
                                                    </Form>}
                                                </div>
                                            </div>
                                        ) : (
                                            <LoadingSpinner />
                                        )}
                                    </Col>
                               
                                    {/* <Col>
                                        {this.props.appToken ? (
                                            <div>
                                                <header >
                                                    <h3>ComandCenter Daily Upload</h3>
                                                </header>
                                                <div>{
                                                    this.state.loadingDaily? <LoadingSpinner/> :
                                                    <Form >
                                                        
                                                       
                                                        <Button onClick={e=>{this.getCCmeters(e); this.setState({loadingDaily:true})}}>Submit</Button>
                                                
                                                    </Form>}
                                                </div>
                                            </div>
                                        ) : (
                                            <LoadingSpinner />
                                        )}
                                    </Col>
                                    <Col>
                                        {this.props.appToken ?
                                            <div>
                                                <header><h3>Master Meter Upload</h3></header>
                                                <div>
                                                    {this.state.loadingMaster ? <LoadingSpinner /> :
                                                        <Form >
                                                            <Button onClick={e=>{this.getMasterMeters(e); this.setState({loadingMaster:true})}}>Submit</Button>
                                                        </Form>
                                                    
                                                    }
                                                </div>
                                            </div>
                                            : <LoadingSpinner/>}
                                    </Col> */}
                                    
                                </Row>
                            </Container>
                        </div>

                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
            </Fragment>




          
        )
    }
}
export default ElectricReads;