import React, { Component } from "react"
import fetch from "isomorphic-fetch"
import * as urls from "../../utils/urlsConfig"
import { Form, Input } from "reactstrap"
import {ServiceRequest} from "../classes/ServiceRequest"
import LoadingSpinner from "../LoadingSpinner"

/**
 * creates new service request in cityworks
 */

class ServiceRequestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: [],
			taigaURL: urls.taiga,
			templates:[],
			
			priority: String,
			assignedTo:String,
			creator: String,
			glAccount: String,
			taigaAuth: "",
			clientAzureToken: "",
			vaultToken: "",
			azureKeyStoreURL: urls.keystoreURL,
			customer:null,
			serviceRequest: new ServiceRequest(),
			loading:false,
			employees:[],
			statuses:[],
		}
		this.getOwnerDetails = this.getOwnerDetails.bind(this);
		this.sendToCityworks=this.sendToCityworks.bind(this);
		this.getProblems=this.getProblems.bind(this);
		this.getEmployees=this.getEmployees.bind(this);
		this.getStatuses=this.getStatuses.bind(this);

	}
	
	componentDidMount() {
		this.getOwnerDetails()
		this.getProblems()
		this.getEmployees()
		this.getStatuses()
		this.state.serviceRequest.setRequestDate(new Date())
		
	}

	sendToCityworks(e){
		e.preventDefault();
		this.setState({loading:true});
		var SR = this.state.serviceRequest.getSR()

		fetch(
			urls.cityWorks +
				"ServiceRequest/Create" ,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
				body:JSON.stringify(SR)
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				}else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				//this.state.customers = data;
				this.state.serviceRequest.clearWO()
				alert("Service request "+data.requestId+" successfully added to Cityworks")
				this.setState({loading:false});
				//alert("Service request "+data[0].problemSid+" successfully added to Cityworks")
					
			})
			.catch(console.log)
			
	}
	getProblems(){
		
		fetch(
			urls.cityWorks +
				"ServiceRequest/Problems?domainId=1" ,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				} else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				} else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				this.setState({templates:data})
								
			})
			.catch(console.log)
	}
	getEmployees(){
		
		fetch(
			urls.cityWorks +
				"Employee/All" ,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				}else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				this.setState({employees:data})
								
			})
			.catch(console.log)
	}
	getStatuses(){
		
		fetch(
			urls.cityWorks +
				"ServiceRequest/Status" ,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					// Authorization: "Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				} else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				} else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				
				this.setState({statuses:data})
								
			})
			.catch(console.log)
	}
	getOwnerDetails(){
		if(this.props.lot.CustomerIndex.length>0){
		fetch(
			urls.springbrook +
				"Customer/ByIndex?customerIndex=" +
				this.props.lot.CustomerIndex,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.props.auth.springBrookAuthAccessToken,
				},
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
					alert("Your token has expired. Please refresh the page.");
					this.setState({loading:false})
				}else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {				
					this.setState({customer: data})
			})
			.catch(console.log)
		}}
	

	render() {
		var templates= this.state.templates.map((item)=>
		<option key={item.problemSid} value={item.problemSid} onClick={e=>{this.state.serviceRequest.setCode(item.problemSid);}} >{item.description} - {item.problemCode} </option>)

		var employee= this.state.employees.map((item)=>
		<option key={item.employeeSid} value={item.employeeSid}>{item.firstName}{" "}{item.lastName} </option>)

		var status= this.state.statuses.map((item)=>
		<option key={item.code} value={item.code}>{item.description} </option>)

		return (
			<div className='modal fade' id='modal-CWserviceRequest' tabindex="-1" >
				<div className='modal-dialog'> 
					<div className='modal-content'>
					<Form autoComplete="off"
							roll='form'
							className='bugForm'
							onSubmit={e=>this.sendToCityworks(e)}
							
						>
							<div className='modal-header bg-orange color-palette'>
								<h4 className='modal-title'>
									Create <b>Cityworks</b> Service Request
								</h4>
								<button
									type='button'
									className='close'
									data-bs-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							{this.state.loading ? <LoadingSpinner />:
							<div className='modal-body'>
								<div className='row'>
										<div className='col-sm-12'>
											{/*text input */}
											<div className='form-group'>
											{this.state.customer != null ? <p><b>Lot: </b>{this.props.lot.streetNumber} {this.props.lot.streetDirectional} {this.props.lot.streetName}  <b>Customer: </b>{this.state.customer[0].firstName} {this.state.customer[0].lastName}</p>:null}
												
											</div>
										</div>
									</div>
									
									<div className='row'>
									{this.state.employees.length > 0 ?
										<div className='col-sm-6'>
											{/*text input */}
											<div className='form-group'>
												<label>Requested By</label>
												<Input
													required
													id='creator'
													type='select'
													className='form-control'
													placeholder='Enter WO creator'
													value={this.state.serviceRequest.RequestBy}
													onChange={(e) =>
														this.state.serviceRequest.setRequestBy(e.target.value)
													}
												><option>---</option>
												{employee}</Input>
											</div>
										</div>:null}

										<div className='col-sm-6'>
											{/*text input */}
											{this.state.templates.length > 0 ?
												<div className='form-group'>
													<label>Type of Service Request</label>
													<Input
														required
														id='dep'
														type='select'
														className='form-control'
														value={this.state.serviceRequest.templateId}
														onChange={(e) =>{
															this.state.serviceRequest.setCode(e.target.value);
														}}
													>
														<option>---</option>
														{templates}
													</Input>
											</div>: null
											}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-6'>
											{/* text input */}
											<div className='form-group'>
												<label>Assigned To</label>
												<Input
													required
													id='assignedTo'
													type='text'
													className='form-control'
													placeholder='Who is doing the work'
													value={this.state.serviceRequest.AssignedTo}
													onChange={(e) =>{
														this.state.serviceRequest.setAssignedTo(e.target.value)
														}
													}
												></Input>
											</div>
										</div>
										<div className='col-sm-6'>
											{this.state.statuses.length>0 ?
											<div className='form-group'>
												<label>Status</label>
												<Input
													id='status'
													type='select'
													className='form-control'
													placeholder=''
													value={this.state.serviceRequest.status}
													onChange={(e) =>
														this.state.serviceRequest.setStatus(e.target.value)
													}
												>
													<option>---</option>
													{status}	
												</Input>
											</div>:null}
										</div>
									</div>
									
									<div className='row'>
										<div className='col-sm-12'>
											{/* textarea */}
											<div className='form-group'>
												<label>Description</label>
												<Input
													required
													id='des'
													type='textarea'
													className='form-control'
													rows='5'
													placeholder='Enter a description'
													value={this.state.serviceRequest.description}
													onChange={(e) =>
														this.state.serviceRequest.setRequestDescription(e.target.value)
													}
												></Input>
											</div>
										</div>
									</div>
								</div>
							}
							{/* end .modal-body */}
													
							<div className='modal-footer justify-content-between'>
								<Input type='submit' className='btn btn-info' />
								<button
									type='button'
									className='btn btn-default'
									data-bs-dismiss='modal'
								>
									Close
								</button>
							</div>
						</Form>
					</div>
					{/* end .modal-content */}
				</div>
				{/* end .modal-dialog */}
			</div>
			// end.modal
		)
	}
}
export default ServiceRequestForm
