
//Dev URLs
// export const homePage='';
// export const wufuPage='https://apiarydev-linux-apiaryinterface.azurewebsites.net/';
// export const esriPage='https://apiarydev-linux-apiaryinterface.azurewebsites.net/';
// export const springbrook='https://apiarydev-windows-ispringbrook.azurewebsites.net/api/';
// export const taiga='https://api.taiga.io/api/v1/';
// export const keystoreURL='https://apiarydev-linux-apiaryinterface.azurewebsites.net/keyVault';
// export const icommsURL='https://apiarydev-windows-icomms.azurewebsites.net/api/';


//Test URLs
// export const homePage='pandora.verawaterandpower.com';
// export const wufooPage='https://apiarytest-linux-apiaryinterface.azurewebsites.net/';
// export const meterReads='https://apiarytest-linux-apiaryinterface.azurewebsites.net/meterReads/';

// export const workMang='https://apiarytest-linux-apiaryinterface.azurewebsites.net/';
// export const esriPage='https://apiarytest-linux-apiaryinterface.azurewebsites.net/';
// export const springbrook='https://apiarytest-windows-ispringbrook.azurewebsites.net/api/';
// export const taiga='https://api.taiga.io/api/v1/';
// export const icommsURL='https://apiarytest-windows-icomms.azurewebsites.net/api/';
// export const keystoreURL='https://apiarytest-linux-apiaryinterface.azurewebsites.net/keyVault';
// export const jsreort='https://vwp.jsreportonline.net';
// export const jstemplate="/WorkOrders/workOrders";
// export const igraph="https://apiarytest-igraph.azurewebsites.net/api/";
// export const cityWorks = "https://apiarytest-icityworks.azurewebsites.net/api/"

// //Prod URLs
export const homePage='apiary.verawaterandpower.com';
export const wufooPage='https://apiaryarcgis.azurewebsites.net/';
export const meterReads='https://apiaryarcgis.azurewebsites.net/meterReads/';

export const workMang='https://apiaryarcgis.azurewebsites.net/';
export const esriPage='https://apiaryarcgis.azurewebsites.net/';
export const springbrook='https://apiaryspringbrook.azurewebsites.net/api/';
export const taiga='https://api.taiga.io/api/v1/';
export const icommsURL='https://apiarycomms.azurewebsites.net/api/';
export const keystoreURL='https://apiaryarcgis.azurewebsites.net/keyVault';
export const jsreort='https://vwp.jsreportonline.net';
export const jstemplate="/WorkOrders/workOrders";
export const igraph="https://apiarygraph.azurewebsites.net/api/";
export const cityWorks = "https://apiaryprod-icityworks.azurewebsites.net/api/"
export const dynamics="https://apiaryprod-dynamics.azurewebsites.net/api/"
export const sprycis="https://apiaryprod-sprycis.azurewebsites.net/api/"