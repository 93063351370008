
export class WorkOrder{
    constructor(){
        
            this.workorder={
                "WorkOrderIndex": null,
                "CreationDate":null,
                "Creator":null, //required
                "Status": null,
                "StartDate":null,
                "CompletionDate":null,
                "DueDate":null,
                "Description": null,
                "Notes": null,
                "Comments":null,
                "Code": null, //required
                "Department":null,
                "TemplateId": null, //required
                "Type":null,
                "Priority": null,
                "AssignTo": null,
                "AssignDate": null,
                "Billable": null,
                "CustomerName":null,
                "Address": null,
                "Location":null,
                "GLIndex": null,

                "LotIndex": null,
                "Approved": null,
                "ApprovedBy":null,
                "CategoryCode": null,
                "Index1": null,
                "woCategory": {
                    
                },
                "ubMaster": {
                    
                },
                "Lot": {
                    
                },
                "Customer": {
                    
                },
                "Meters": [
                    
                ],
                "WoArAccount": {
                    
                }
                } 
          
    }
    setWO(newWO){
        this.workorder={
            "Type":newWO.Type,
            "WorkOrderIndex": newWO.WorkOrderIndex,
            "LotIndex": newWO.LotIndex,
            "Department":newWO.Department,
            "Code":newWO.Code, //required
            "TemplateId":newWO.TemplateId, //required
            "Creator":newWO.Creator, //required
            "Approved": newWO.approved,
            "ApprovedBy":newWO.approvedBy,
            "Status": newWO.Status,
            "Priority": newWO.Priority,
            "Description": newWO.Description,
            "Notes": newWO.Notes,
            "AssignTo": newWO.AssignTo,
            "AssignDate": newWO.AssignDate,
            "CategoryCode": newWO.categoryCode,
            "CreationDate":newWO.CreationDate,
            "StartDate":newWO.StartDate,
            "Address": newWO.Address,
            "Location":newWO.Location,
            "Index": newWO.index1,
            "Bllable": newWO.billable,
            "GLIndex": newWO.GLIndex,
            "woCategory": newWO.woCategory,
            "ubMaster": newWO.ubMaster,
            "Lot": newWO.lot,
            "Customer": newWO.customer,
            "CustomerName": newWO.customerName,
            "Meters": newWO.meters,
            "WoArAccount": newWO.woArAccount,
           
            } 
        }
    setType(type){
        this.workorder.Type=type
    }
    setTemplateId(id){
        this.workorder.TemplateId=id
    }
    setDepartment(department){
        this.workorder.Department=department
    }
    setGL(GLIndex){
        this.workorder.GLIndex=GLIndex
    }
    setAssignedTo(AssignTo){
        this.workorder.AssignTo=AssignTo
    }
    setAssignDate(date){
        this.workorder.AssignDate=date
    }
    setAddress1(streetNumber,streetDirectional,streetName){
        this.workorder.Address=streetNumber+" "+streetDirectional+" "+streetName;
    }
    setAddress(address){
        this.workorder.Address=address
    }
    setLocation(location){
        this.workorder.Location=location
    }
    setIndex1(first,last){
        this.workorder.Index1=first+" "+last
    }
    setLotIndex(LotIndex){
        this.workorder.LotIndex=LotIndex;
    }
    setCreator(creator){
        this.workorder.Creator=creator
    }
    setWOStatus(woStatus){
        this.workorder.Status=woStatus
    } 
    setPriority(priority){
        this.workorder.Priority=priority
    }
    setDescription(description){
        this.workorder.Description=description
    }
    setCustomer(customer){
        this.workorder.Customer=customer[0];
        this.setIndex1(customer[0].firstName,customer[0].lastName)
        console.log(this.workorder)
    }
    setCustomerName(name){
        this.workorder.CustomerName = name
    }
    setLot(lot){
        this.workorder.Lot=lot
        this.setAddress1(lot.streetNumber,lot.streetDirectional,lot.streetName)
        this.setLotIndex(lot.LotIndex)
    }
    setMeter(meter){
        this.workorder.Meter.push(meter)
    } 
    setWOARAccount(arAccount){
        this.workorder.WoArAccount=arAccount
    }
    setUBMaster(ubMaster){
        this.workorder.ubMaster=ubMaster
    }
    setWOCategory(woCategory){
        this.workorder.woCategory=woCategory
    }
    setCode(code){
        this.workorder.Code=code
        this.workorder.CategoryCode = code
    }
    setCreationDate(date){
        this.workorder.CreationDate=date
    }
    setNotes(notes){
        this.workorder.Notes=notes
    }
  
    clearWO(){
        this.workorder={
            "WorkOrderIndex": null,
            "LotIndex": null,
            "Department":null,
            "Code": null,
            "TemplateId": null,
            "Creator":null,
            "Approved": null,
            "ApprovedBy":null,
            "Status": null,
            "Priority": null,
            "Description": null,
            "Notes": null,
            "AssignTo": null,
            "AssignDate": null,
            "CategoryCode": null,
            "Address": null,
            "Location":null,
            "Index1": null,
            "Billable": null,
            "GLIndex": null,
            "CreationDate":null,
            "CustomerName":null,
            
            "woCategory": {
             
            },
            "ubMaster": {
              
            },
            "Lot": {
             
            },
            "Customer": {
             
            },
            "Meters": [
             
            ],
            "WoArAccount": {
              
            }
          }   

    }
    getWO(){
        return this.workorder
    }
    getWOForUpdate(){
        return {
            "WorkOrderIndex": this.workorder.WorkOrderIndex,
            "Address": this.workorder.Address,
            "Status": this.workorder.Status,
            "Priority": this.workorder.Priority,
            "StartDate": this.workorder.StartDate,
            "Description": this.workorder.Description,
            "Notes": this.workorder.Notes,
            "Location": this.workorder.Location,
            "GLIndex": this.workorder.GLIndex
        }
    }
}