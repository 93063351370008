
export class ServiceRequest{
    constructor(newSR){
        if(newSR){
        this.serviceRequest={
                    
            "ServiceRequestIndex": newSR.ServiceRequestIndex,
            "code": newSR.Code,//required for Cityworks
            "requestBy":newSR.RequestBy,//required for Cityworks
            "requestDate":newSR.RequestDate, //required for Cityworks
            "requestDescription":"",
            "assignTo":"",
            "assignDate":newSR.AssignDate,
            "serviceBy": newSR.ServiceBy,
            "serviceDate":newSR.ServiceDate,
            "serviceDescription": newSR.ServiceDescription,
            "status": newSR.status, //required for Cityworks
            "closeBy": newSR.CloseBy,
            "closeDate": newSR.CloseDate,
            "charge": newSR.Charge,
            "priority": newSR.Priority,
            "address": newSR.Address,
           
            "address2": newSR.Address2,
            "city": newSR.City,
            "state": newSR.State,
            "zip": newSR.Zip,
            
            "location": newSR.Location,
            "latitude": newSR.Latitude,
            "longitude": newSR.Longitude,
           
            } }
        else{
            this.serviceRequest={
                "ServiceRequestIndex": null,
                "code": null,
                "requestBy": null,
                "requestDate": null,
                "requestDescription": null,
                "assignTo": null,
                "assignDate": null,
                "serviceBy": null,
                "serviceDate": null,
                "serviceDescription": null,
                "status":  "Active",
                "closeBy": null,
                "closeDate": null,
                "charge": null,
                "priority": null,
                "address": null,
                "address2": null,
                "city": null,
                "state": null,
                "zip": null,
                "location": null,
                "latitude": null,
                "longitude": null,            
                } 
        }   
    }
    setCode(code){
        this.serviceRequest.code=code
    }
    setRequestBy(RequestBy){
        this.serviceRequest.requestBy=RequestBy
    } 
    setRequestDate(date){
        this.serviceRequest.requestDate=date
    }
    setRequestDescription(RequestDescription){
        this.serviceRequest.requestDescription=RequestDescription
    }
    setAssignedTo(assignedTo){
        this.serviceRequest.assignedTo=assignedTo
    }
    setAssignDate(AssignDate){
            this.serviceRequest.assignDate=AssignDate
        }
    setServiceBy(ServiceBy){
            this.serviceRequest.serviceBy=ServiceBy;
        }
    setServiceDate(ServiceDate){
        this.serviceRequest.serviceDate=ServiceDate
    }
    setServiceDescription(ServiceDescription){
        this.serviceRequest.serviceDescription=ServiceDescription
    }
    setStatus(Status){
        this.serviceRequest.status=Status
    }
    setCloseBy(CloseBy){
        this.serviceRequest.closeBy=CloseBy
    }
    setCloseDate(CloseDate){
        this.serviceRequest.closeDate=CloseDate;
    }
    setCharge(Charge){
        this.serviceRequest.charge=Charge
    }
    setPriority(priority){
        this.serviceRequest.priority=priority
    }
    setAddress(streetNumber,streetDirectional,streetName){
        this.serviceRequest.address=streetNumber+" "+streetDirectional+" "+streetName;
    }
    setAddress2(Address2){
        this.serviceRequest.address2=Address2
    } 
    setCity(City){
        this.serviceRequest.city=City
    }
    setState(State){
        this.serviceRequest.state=State
    }
    setZip(Zip){
        this.serviceRequest.zip=Zip
    }
    setLocation(Location){
        this.serviceRequest.location=Location
    }
    setLatitude(Latitude){
        this.serviceRequest.latitude=Latitude
    }
    setLongitude(Longitude){
        this.serviceRequest.longitude=Longitude
    }
    clearWO(){
        this.serviceRequest={
            "ServiceRequestIndex": null,
                "code": null,
                "requestBy": null,
                "requestDate": null,
                "requestDescription": null,
                "assignTo": null,
                "assignDate": null,
                "serviceBy": null,
                "serviceDate": null,
                "serviceDescription": null,
                "status":  "Active",
                "closeBy": null,
                "closeDate": null,
                "charge": null,
                "priority": null,
                "address": null,
                "address2": null,
                "city": null,
                "state": null,
                "zip": null,
                "location": null,
                "latitude": null,
                "longitude": null,    
          }   

    }
    getSR(){
        return this.serviceRequest
    }
}