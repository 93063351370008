import React, { Component } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { PublicClientApplication } from "@azure/msal-browser"
import {
	msalConfig,
	loginApiaryRequest,
	apiaryTokenRequest,
	apiarySilentRequest,
	loginSpringBrookRequest,
	springBrookTokenRequest,
	springBrookSilentRequest,
	dynamicsSilentRequest,
	cityworksSilentRequest,
	loginIcommsRequest,
	icommsTokenRequest,
	icommsSilentRequest,
	igraphSilentRequest,
	igraphTokenRequest,
	dynamicsTokenRequest,
	cityworksTokenRequest,
	loginGraphRequest
} from "./authConfig"

const isIE = () => {
	const ua = window.navigator.userAgent
	const msie = ua.indexOf("MSIE ") > -1
	const msie11 = ua.indexOf("Trident/") > -1

	// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
	// const isEdge = ua.indexOf("Edge/") > -1;

	return msie || msie11
}

// If you support IE, our recommendation is that you sign-in using Redirect flow
const useRedirectFlow = isIE()

const msalApp = new PublicClientApplication(msalConfig)

const AuthHOC = (WrappedComponent) =>
	class AuthProvider extends Component {
		constructor(props) {
			super(props)

			this.state = {
				account: null,
				error: null,
				username: null,
				isAuthenticated: false,
			}
		}

		componentDidMount = () => {
			if (useRedirectFlow) {
				msalApp
					.handleRedirectPromise()
					.then(this.handleResponse)
					.catch((err) => {
						console.error(err)
						this.setState({ error: err.errorMessage })
					})
			}

			this.getAccounts()
		}

		getAccounts = () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			const currentAccounts = msalApp.getAllAccounts()

			if (currentAccounts === null) {
				console.error("No accounts detected!")
				return
			} else if (currentAccounts.length > 1) {
				console.warn("Multiple accounts detected.")
				// Add choose account code here
				var expire = new Date(currentAccounts[0].idTokenClaims.exp*1000);
				var curDate = new Date()
				if(expire>curDate){
				this.setState({
					username: currentAccounts[0].username,
					account: msalApp.getAccountByUsername(
						currentAccounts[0].username
					),
					isAuthenticated: true,
				})}
				else{
					this.acquireApiaryToken();
					this.acquireSpringBrookToken();
					this.acquireIcommsToken();
					this.acquireGraphToken();
					this.acquireCityworksToken();
					this.acquireDynamicsToken();
				}
			} else if (currentAccounts.length === 1) {
				// Add choose account code here
				// var expire = new Date(currentAccounts[0].idTokenClaims.exp*1000);
				// var curDate = new Date()
				if(expire>curDate){
				this.setState({
					username: currentAccounts[0].username,
					account: msalApp.getAccountByUsername(
						currentAccounts[0].username
					),
					isAuthenticated: true,
				})}
				else{
					this.acquireApiaryToken();
					this.acquireSpringBrookToken();
					this.acquireIcommsToken();
					this.acquireGraphToken();
					this.acquireCityworksToken();
					this.acquireDynamicsToken();
				}
			}
		}

		// export RequestProfileData(token) {
			
		// 	callMsGraph(token).then(response => {return response});
			
		// }

		handleResponse = (response) => {
			if (response !== null) {
				
			
				this.setState({
					account: response.account,
					username: response.account.username,
					isAuthenticated: true,
				})
			} else {
				this.getAccounts()
			}
		}

		acquireApiaryToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			apiarySilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(apiarySilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						apiaryTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(apiaryTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		acquireSpringBrookToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			springBrookSilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(springBrookSilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						springBrookTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(springBrookTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		acquireIcommsToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			icommsSilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(icommsSilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						icommsTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(icommsTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		acquireGraphToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			igraphSilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(igraphSilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						igraphTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(igraphTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		acquireDynamicsToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			dynamicsSilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(dynamicsSilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						dynamicsTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(dynamicsTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		acquireCityworksToken = async () => {
			/**
			 * See here for more info on account retrieval:
			 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
			 */
			cityworksSilentRequest.account = msalApp.getAccountByUsername(
				this.state.username
			)

			return msalApp
				.acquireTokenSilent(cityworksSilentRequest)
				.catch((error) => {
					console.warn(
						"silent token acquisition fails. acquiring token using interactive method"
					)
					if (error) {
						// fallback to interaction when silent call fails
						cityworksTokenRequest.account = msalApp.getAccountByUsername(
							this.state.username
						)

						return msalApp
							.acquireTokenPopup(cityworksTokenRequest)
							.then(this.handleResponse)
							.catch((err) => {
								console.error(err)
								this.setState({ error: err.errorMessage })
							})
					} else {
						console.warn(error)
					}
				})
		}
		signIn = async (redirect) => {
			if (redirect) {
				return msalApp.loginRedirect(loginApiaryRequest)
			}

			return msalApp
				.loginPopup(loginApiaryRequest)
				.then(this.handleResponse)
				.catch((err) => {
					console.log(err)
					this.setState({ error: err.errorMessage })
				})
		}
		signInIcomms = async (redirect) => {
			if (redirect) {
				return msalApp.loginRedirect(loginIcommsRequest)
			}

			return msalApp
				.loginPopup(loginIcommsRequest)
				.then(this.handleResponse)
				.catch((err) => {
					console.log(err)
					this.setState({ error: err.errorMessage })
				})
		}
		signInSpringBrook = async (redirect) => {
			if (redirect) {
				return msalApp.loginRedirect(loginSpringBrookRequest)
			}

			return msalApp
				.loginPopup(loginSpringBrookRequest)
				.then(this.handleResponse)
				.catch((err) => {
					console.log(err)
					this.setState({ error: err.errorMessage })
				})
		}
		signInIGraph = async (redirect) => {
			if (redirect) {
				return msalApp.loginRedirect(loginGraphRequest)
			}

			return msalApp
				.loginPopup(loginGraphRequest)
				.then(this.handleResponse)
				.catch((err) => {
					console.log(err)
					this.setState({ error: err.errorMessage })
				})
		}
		signOut = async () => {
			const logoutRequest = {
				account: msalApp.getAccountByUsername(this.state.username),
			}

			return msalApp.logout(logoutRequest)
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					account={this.state.account}
					error={this.state.error}
					isAuthenticated={this.state.isAuthenticated}
					signIn={() => this.signIn(useRedirectFlow)}
					signOut={() => this.signOut()}
					acquireApiaryToken={() => this.acquireApiaryToken()}
					acquireSpringBrookToken={() =>
						this.acquireSpringBrookToken()
					}
					acquireIcommsToken={() => this.acquireIcommsToken()}
					acquireGraphToken={()=>this.acquireGraphToken()}
					acquireDynamicsToken={()=>this.acquireDynamicsToken()}
					acquireCityworksToken={()=>this.acquireCityworksToken()}
				/>
			)
		}
	}

const mapStateToProps = (state) => state

export default compose(connect(mapStateToProps), AuthHOC)
