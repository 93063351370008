import React, { Component } from "react"
import { connect } from "react-redux"

import Main from "../components/main/Main"
import { updateUI } from "../actions/updateActions"
import * as urls from "../utils/urlsConfig"
import { getProfile, postProfile, putProfile } from "../actions/serviceActions"
import {defaultView} from "../utils/roles"


class MainContainer extends Component {

	componentDidMount() {
		// acquire the token and update the store
			// console.log("apiary token is null")
			this.props.acquireApiaryToken().then((response) => {
				if (response) {
					// set access token
					this.props.updateApiaryToken(response)
					
				}
			}).then(()=>
			// this.props.acquireCityworksToken().then((response) => {
			// 	if (response) {
			// 		// set access token
			// 		this.props.updateCityworksToken(response)

			// 	}
			// })).then(()=>
			// this.props.acquireDynamicsToken().then((response) => {
			// 	if (response) {
			// 		// set access token
			// 		this.props.updateDynamicsToken(response)

			// 	}
			// })).then(()=>
			this.props.acquireIcommsToken().then((response) => {
				if (response) {
					// set access token
					this.props.updateIcommsToken(response)

				}
			})).then(()=>
		
		
			this.props.acquireSpringBrookToken().then((response) => {
				if (response) {
					// set access token
					this.props.updateSpringBrookToken(response);
					
					fetch(urls.springbrook + "Meter/Search", {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + response.accessToken,
						},
					})
						.then(function (response) {
							if (response.ok) {
								return response
							} else if(response.status === 401){
								alert("Your token has expired. Please refresh the page");
								this.setState({loading:false})
							} else {
								var error = new Error(response.statusText)
								throw error
							}
						})
						.then((res) => res.json())
						.then((data) => {
							this.props.updateSearchMeters(data);
						})
						.catch(console.log)
						
					fetch(urls.springbrook + "Lot/search", {
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Authorization:
									"Bearer " + response.accessToken,
							},
						})
							.then(function (response) {
								if (response.ok) {
									return response
								} else if(response.status === 401){
									alert("Your token has expired. Please refresh the page");
									this.setState({loading:false})
								} else {
									var error = new Error(response.statusText)
									throw error
								}
							})
							.then((res) => res.json())
							.then((data) => {
								this.props.updateSearchLots(data);
							})
							.catch(console.log)
							
					fetch(urls.springbrook + "/Customer/Search", {
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization:
										"Bearer " + response.accessToken,
								},
							})
								.then(function (response) {
									if (response.ok) {
										return response
									} else if(response.status === 401){
										alert("Your token has expired. Please refresh the page");
										this.setState({loading:false})
									} else {
										var error = new Error(response.statusText)
										throw error
									}
								})
								.then((res) => res.json())
								.then((data) => {
									this.props.updateSearchAccounts(data);

								})
								.catch(console.log)
				}
			})).then(()=>
		
		
			this.props.acquireGraphToken().then((response) => {
				if (response) {
					// set access token
					this.props.updateGraphToken(response);
					
					fetch(urls.igraph+"storage/userinfo", {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + response.accessToken
						}
					}).then(function (response) {
						if (response.ok) {
							return response
						}  else if(response.status === 401){
							alert("Your token has expired. Please refresh the page");
							this.setState({loading:false})
						}else {
							var error = new Error(response.statusText)
							throw error
						}
					})
					.then((res) => res.json())
					.then((data) => {
						if (data) {
							console.log("updating profiles")
							this.props.updateProfile(data);
							
						}
					})
					.catch(err => console.log(err));
					var roles;
					fetch(urls.igraph+"user/apiaryroles", {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + response.accessToken
						}
					}).then(function (response) {
						if (response.ok) {
							return response
						} else if(response.status === 401){
							alert("Your token has expired. Please refresh the page");
							this.setState({loading:false})
						} else {
							var error = new Error(response.statusText)
							throw error
						}
					})
					.then((res) => res.json())
					.then((data) => {
						if (data) {
							roles=data[0].appRoles;
				
							fetch(urls.igraph+"user/userapiaryrole ", {
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + response.accessToken
								}
							}).then(function (response) {
								if (response.ok) {
									return response
								} else if(response.status === 401){
									alert("Your token has expired. Please refresh the page");
									this.setState({loading:false})
								} else {
									var error = new Error(response.statusText)
									throw error
								}
							})
							.then((res) => res.json())
							.then((data) => {
								for(var i = 0;i<roles.length;i++){
									if(roles[i].id===data[0].appRoleId){
										var roleName;
										if(roles[i].displayName==="Customer Service"){roleName = defaultView.Customer_Service}
										else if(roles[i].displayName==="Default User"){roleName = defaultView.Default_User}
										else if(roles[i].displayName==="Super User"){roleName = defaultView.Super_User}
										else if(roles[i].displayName==="Billing"){roleName = defaultView.Billing}
										else if(roles[i].displayName==="Credit"){roleName = defaultView.Credit}
										else if(roles[i].displayName==="Operations"){roleName = defaultView.Operations}
										else if(roles[i].displayName==="Finance"){roleName = defaultView.Finance}
										else if(roles[i].displayName==="General Manager"){roleName = defaultView.GenMan}
										else if(roles[i].displayName==="Engineer"){roleName = defaultView.Engineer}
										else if(roles[i].displayName==="Mapping"){roleName = defaultView.Mapping}
										else if(roles[i].displayName==="Communications"){roleName = defaultView.Communications}

										this.props.updateView({view:roleName,viewName:roles[i].displayName})
									}
								}
								// console.log("role should be set now")
								// console.log(data)
							})
							.catch(err => console.log(err));
						}
					})
					.catch(err => console.log(err));
				}
			}))
		

	}

	render() {
		
		
		return (
			<div>
				<Main {...this.props} />
			</div>
		)
	}
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({
	
	updateUI: (payload) => {
		dispatch(updateUI(payload))
	},
	getProfile: (id) => {
		dispatch(getProfile(id))
	},
	postProfile: (profile) => {
		dispatch(postProfile(profile))
	},
	putProfile: (profile) => {
		dispatch(putProfile(profile))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
