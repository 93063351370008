import React, { Component } from "react"
import fetch from "isomorphic-fetch"
import { Form } from "reactstrap"

/**
 * displayes the users details
 */

class Profile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			form: [],
		
		}
		this.getTaigaKey = this.getTaigaKey.bind(this);

	}
	
	componentDidMount() {
	}

	getTaigaKey() {
		if (this.props.auth.apiaryAuthAccessToken) {
			fetch(this.state.azureKeyStoreURL + "/taiga", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization:
						"Bearer " + this.props.auth.apiaryAuthAccessToken,
				},
			})
				.then(function (response) {
					if (response.ok) {
						return response
					}  else if(response.status === 401){
						alert("Your token has expired. Please refresh the page.");
						this.setState({loading:false})
					}else {
						console.log("response error")
						var error = new Error(response.statusText)
						throw error
					}
				})
				.then((res) => res.text())
				.then((data) => {
					this.setState({ taigaAuth: data })
				})
				.catch(console.log)
		} else {
			console.log("got to wait for token")
		}
	}
	

	render() {
		return (
			<div className='modal fade' id='modal-profile' tabindex="-1" >
				<div className='modal-dialog'>
					<div className='modal-content'>
					<Form
							roll='form'
							className='bugForm'
							onSubmit={e=>this.sendToTaiga(e)}
							
						>
							<div className='modal-header'>
								<h4 className='modal-title'>
									User Profile
								</h4>
								<button
									type='button'
									className='close'
									data-bs-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<div className='row'>
									<div className='col-sm-12'>
										{/*text input */}
										<div className='form-group'>
											<p>{this.props.auth.apiaryAuthAccount.account.name}, this is where your profile information will be</p>
											<p>Job Title: {this.props.profile.jobTitle}</p>
											<p>Office Location: {this.props.profile.officeLocation}</p>
											<p>Listed Number: {this.props.profile.mobilePhone}</p>
											<p>Current View is set to {this.props.profile.viewName}</p>
										</div>
									</div>
								</div>
								
								
							</div>
							{/* end .modal-body */}

							<div className='modal-footer justify-content-between'>
								{/* <Input type='submit' className='btn btn-info' /> */}
								<button
									type='button'
									className='btn btn-default text-center bg-secondary'
									data-bs-dismiss='modal'
								>
									Close
								</button>
								<button 
									type='button'
									className='btn btn-default bg-danger text-center ' 
									data-bs-dismiss='modal' 
									onClick={e=>this.props.signOut()}
								>
									Sign Out
								</button>
							</div>
						</Form>
					</div>
					{/* end .modal-content */}
				</div>
				{/* end .modal-dialog */}
			</div>
			// end.modal
		)
	}
}
export default Profile
