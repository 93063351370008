import {
    updateProfile,
    updateUI,
} from './updateActions';

import { ApiaryInterfaceConfig } from '../utils/authConfig';

export const getProfile = (user,token) => (dispatch, getState) => {
    fetch("https://apiarydev-windows-igraph.azurewebsites.net/api/storage/userinfo", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            }).then(function (response) {
                if (response.ok) {
                    return response
                } else {
                    var error = new Error(response.statusText)
                    throw error
                }
            })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    dispatch(updateProfile(data));
                    console.log(data);

            // if the user exists, skip registration
            // if (getState().ui.component === 1) {
            //     dispatch(updateUI(3));
            // }
        }
    })
    .catch(err => console.log(err));
};

export const postProfile = (profile) => (dispatch, getState) => {
    return fetch(ApiaryInterfaceConfig.resourceUri, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${getState().auth.apiaryAuthAccessToken}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(profile)
    }).then(response => {
        console.log(response)
        if (response && response.status !== 404) {
            return response.json();
        }
    })
    .then((response) => {
        console.log(response)
        if (response === 'interaction required') {
            // trigger interaction
        } else {
            dispatch(updateProfile(response))
            dispatch(updateUI(2))
        }
    })
    .catch(err => console.log(err))
};

export const putProfile = (profile) => (dispatch, getState) => {
    return fetch(ApiaryInterfaceConfig.resourceUri + '/' + profile.id, {
        method: 'PUT',
        headers: {
            "Authorization": `Bearer ${getState().auth.apiaryAuthAccessToken}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(profile)
    }).then(() => dispatch(updateProfile(profile)))
    .then(() => dispatch(updateUI(3)))
    .catch(err => console.log(err));
};