export const defaultView={
   
    Super_User:{
        tools:{
            payments:true,
            electric:true,
            water:true,
            disconnects:true,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"collapsed-card", 
            electricExpand:"collapsed-card", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"collapsed-card",
            workOrdersExpand:"collapsed-card",
            serviceRequestExpand:"collapsed-card",
        },
        reports:{
            woPrint:true,
			pickList:true,
			apCert:true,
			rentToOwn:true,
            basementWater:true,
            workOrderExpand:"collapsed-card",
            pickListExpand:"collapsed-card",
            apCertExpand:"collapsed-card",
            rentToOwnExpand:"collapsed-card",
            basementWaterExpand:"collapsed-card",

        }
    },
    Default_User:{
        tools:{
            payments:false,
            electric:false,
            water:false,
            disconnects:false,
            workOrders:false,
            serviceRequest:false,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Billing:{
        tools:{
            payments:true,
            electric:true,
            water:true,
            disconnects:true,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"collapsed-card", 
            electricExpand:"collapsed-card", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"collapsed-card",
            workOrdersExpand:"collapsed-card",
            serviceRequestExpand:"collapsed-card",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:false,
			rentToOwn:true,
            basementWater:true,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"collapsed-card",
            basementWaterExpand:"collapsed-card",
        }
    },
    Customer_Service:{
        tools:{
            payments:true,
            electric:false,
            water:true,
            disconnects:false,
            workOrders:false,
            serviceRequest:true,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Credit:{
        tools:{
            payments:true,
            electric:true,
            water:true,
            disconnects:true,
            workOrders:false,
            serviceRequest:false,
            paymentsExpand:"collapsed-card", 
            electricExpand:"collapsed-card", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"collapsed-card",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    
    },
    Operations:{
        tools:{
            payments:false,
            electric:false,
            water:false,
            disconnects:false,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:true,
			pickList:true,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Finance:{
        tools:{
            payments:true,
            electric:false,
            water:false,
            disconnects:true,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"collapsed-card", 
            electricExpand:"", 
            waterExpand:"",
            disconnectsExpand:"collapsed-card",
            workOrdersExpand:"collapsed-card",
            serviceRequestExpand:"collapsed-card",
        },
        reports:{
            woPrint:true,
			pickList:true,
			apCert:true,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"collapsed-card",
            pickListExpand:"collapsed-card",
            apCertExpand:"collapsed-card",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    GenMan:{
        tools:{
            payments:false,
            electric:true,
            water:true,
            disconnects:false,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"collapsed-card",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:true,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Engineer:{
        tools:{
            payments:false,
            electric:false,
            water:false,
            disconnects:false,
            workOrders:false,
            serviceRequest:false,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:true,
			pickList:true,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Mapping:{
        tools:{
            payments:false,
            electric:false,
            water:false,
            disconnects:false,
            workOrders:true,
            serviceRequest:true,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:true,
			pickList:false,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
    Communications:{
        tools:{
            payments:false,
            electric:false,
            water:false,
            disconnects:false,
            workOrders:false,
            serviceRequest:false,
            paymentsExpand:"", 
            electricExpand:"", 
            waterExpand:"",
            disconnectsExpand:"",
            workOrdersExpand:"",
            serviceRequestExpand:"",
        },
        reports:{
            woPrint:false,
			pickList:false,
			apCert:false,
			rentToOwn:false,
            basementWater:false,
            workOrderExpand:"",
            pickListExpand:"",
            apCertExpand:"",
            rentToOwnExpand:"",
            basementWaterExpand:"",
        }
    },
}