import React, { Component} from 'react';
import fetch from 'isomorphic-fetch';
import { Button, Form, Input } from 'reactstrap';
import LoadingSpinner from './../../LoadingSpinner';
import * as urls from '../../../utils/urlsConfig';
import * as fileSaver from 'file-saver';
import { number } from 'prop-types';


class Ipay extends Component {
    constructor(props) {
        super(props);
        this.state = {
          //uploaded:React.createRef(),
          sbURL:urls.sprycis+'Account/PaymentImportFile',
          icommsURL:urls.icommsURL+'Sftp/FileByName',
          loading:false,
          uploaded:[],
          fileName:'',
          sharepointFile:[],
          fileDate:'',
          availableFiles:[],
          selectedFile:number,
          submitAvailable:false
        };
        this.toggleLoading = this.toggleLoading.bind(this);
        this.submitIpayFile = this.submitIpayFile.bind(this);
        this.saveFile=this.saveFile.bind(this);
        this.getAvailableFiles=this.getAvailableFiles.bind(this);
        this.downloadRawData = this.downloadRawData.bind(this);
      }

    toggleLoading(){
        this.setState({loading:!this.state.loading})
    }
    
    componentDidMount(){
        this.getAvailableFiles()
    }

    getAvailableFiles(){
        this.setState({loading:true})
        fetch(
			urls.icommsURL +
				"Sftp/Directory?serviceName=ipay" ,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + this.props.auth.icommsAuthAccessToken,
				},
				
			}
		)
			.then(function (response) {
				if (response.ok) {
					return response
				}  else if(response.status === 401){
                    alert("Your token has expired. Please refresh the page.");
                    this.setState({loading:false})
                }
                else if(response.status === 502){
                    alert("iPay is not currently available. Please check back later.");
                    var error = new Error(response.statusText)
					throw error
                }else {
					var error = new Error(response.statusText)
					throw error
				}
			})
			.then((res) => res.json())
			.then((data) => {
				console.log(data)
                //data = data.slice(-32,)
                var tempFiles=[]
                for(var i = 0;i<data.length;i++){
                    if(!data[i].isDirectory){
                        tempFiles.push(data[i])
                    }
                }
                console.log(tempFiles)
                tempFiles.sort((a,b)=>{return a.modifyDate-b.modifyDate})
                for(var i=tempFiles.length;i>0;i--){
                    this.state.availableFiles.push(tempFiles[i-1])
                }
                console.log(this.state.availableFiles)

				this.setState({loading:false})
								
			})
			.catch(e=>{
                console.log(e);
                this.setState({loading:false})
            })
    }

    downloadRawData(fileData){
        if(fileData.length===0){
            alert("download failed");
        }else{
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                //res.download(fileName);  
            }
            else {
                try{
                    // var towrite = ["MapID,Merchant Name,Customer Account Number,Customer Name,Customer_Address,Customer Address2,Customer City,Customer_State,Customer Zip Code,Payment Description,Payment Amount,Payment Date,Return Reason Code,Return_Date,Adjustment Amount,Original Payment Date"]
                    // for(var i=0;i<fileData.length;i++){
                    //     var datestr=fileData[i].transactionDate.replace(/\//g,'');
                    //         towrite.push(fileData[i]);

                    // }
                    var a = new Blob([fileData],{type:"text/csv;charset=utf-8"});
                    //a.write(res);
                    var rawFileName="Raw"+this.state.fileName;
                    fileSaver.saveAs(a,rawFileName);
                    // this.state.sharepointFile.push(towrite)
                    // this.saveFile()
                }
                catch(error){
                    console.log(error);
                    //res.download(fileName);
                }
            }
        }
    }

    saveFile(e){
        e.preventDefault();
        var file = new File([this.state.uploaded],this.state.fileName,{type: "text/csv",})
        var uploadFile=new FormData()
        uploadFile.append("FormFileHandle",file)
        uploadFile.append("Service","ipay")
            fetch(urls.igraph+'Storage/UploadPaymentFile', {
                method:"POST",
                ProceData: false,
                headers:{                    
                    'Authorization': 'Bearer '+this.props.auth.igraphAuthAccessToken,
                },
                body:uploadFile
            }).then(function(response) {
                if (response.ok) {
                    return response
                }  else if(response.status === 401){
                    alert("Your token has expired. Please refresh the page.");
                    this.setState({loading:false})
                }else {
                    var error = new Error(response.statusText);
                    error.response = response;
                    alert("something went wrong while trying to save the file to sharepoint");
                    throw error;
                }
            })
            .then(res=>res.json())
            .then(data=> {
                alert("Sharepoint says: "+data)
                
                
            }).catch(console.log);
           
                
                
          
        
            this.setState({uploaded:[],loading:false,submitAvailable:false});

      
    }
    submitIpayFile(e){
        e.preventDefault();
        this.setState({loading:true})
        //this.saveFile()
        fetch(this.state.icommsURL+"?serviceName=ipay&fileName="+this.state.availableFiles[this.state.selectedFile].remoteFileName, {
            method:"GET",
            headers:{'Authorization': 'Bearer '+this.props.icommsToken},
           
        }).then(function(response) {
            if (response.ok) {
                
                return response
            }  else if(response.status === 401){
                alert("Your token has expired. Please refresh the page.");
                this.setState({loading:false})
            }else {
                var error = new Error(response.statusText);
                error.response = response;
                alert("something went wrong trying to get Ipay From Icomms");
                throw error;
            }
        })
        .then(res =>{ 
             for (var pair of res.headers.entries()) {
                if(pair[0]==='filedate'){
                    this.setState({fileDate:pair[1].replace(/\s[0-9]+:[0-9]+:[0-9]+\s[A-Z]*$/,'')})
                }
             }
            
            return res.blob()})
        .then(data => {
            
            this.setState({fileName:"Ipay"+this.state.fileDate.replace(/\//g,'')+".csv"})
            this.setState({uploaded:data})
            if(data.length===0){
                
                alert("failed to retrieve selected file for Ipay");
            }
            else{
                this.downloadRawData(data);
                var file=new File([data],this.state.fileName)
                var formUpload = new FormData()
                formUpload.append("FileHandle",file)
                formUpload.append("ServiceName","fiserv")
                this.state.uploaded=data;
                this.saveFile(e)
        }
        }).catch(console.log);
    }
    
    handleFileSelection = (e) => {
		this.setState({ selectedFile: e.target.value,submitAvailable:true})
	}

    render(){
        
        var availableDates = this.state.availableFiles.map((file,index)=>{
            var dateStr = new Date(file.modifyDate).toLocaleDateString();
            return(<option key={file.modifyDate} 
                    value={index}
                    >
                        {dateStr}
            </option>)
        })

        return (
            <div>
                
                <header >
                    <h1>Ipay</h1>
                </header>
                <div>{
                    this.state.loading ? <LoadingSpinner/> :
                    
                    <Form>
                        <Input  type="select" 
                            name="select" 
                            id="exampleSelect" 
                            value={this.state.selectedFile.modifyDate} 
                            required 
                            onChange={e=>this.handleFileSelection(e)}
                            style={{"width":"100%","margin-bottom":"5px"}}>
                            <option value={-1}> ------ </option>
                            {availableDates}
                        </Input>
                        <Button type="submit" disabled={!this.state.submitAvailable}
                        onClick={e=>{this.submitIpayFile(e);this.setState({loading:true})}}>Submit File</Button>
                    </Form>
                    }
                </div>
            </div>
        )
    }
}
export default Ipay;