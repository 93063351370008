// For a full list of MSAL.js configuration parameters,
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig = {
    auth: {
        clientId: "eb1d6b18-7d81-4771-826f-dc57a0a12422",
        authority: "https://login.microsoftonline.com/ebba2929-765b-48f7-8c03-9b450ed099ba",
        redirectUri: "https://apiary.verawaterandpower.com/"
		//redirectUri:"http://localhost:3000"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

// Coordinates and required scopes for your web API
export const ApiaryInterfaceConfig = {
    uri: "https://apiaryarcgis.azurewebsites.net", // e.g. http://localhost:5000/api
    resourceScopes: [ "api://6f587ffe-5e85-48fa-bbea-e29ea45c8fb7/.default"] // e.g. [api//middle_tier_api_client_id/.default]
};
export const IcommsConfig = {
    uri: "https://apiarycomms.azurewebsites.net", // e.g. http://localhost:5000/api
    resourceScopes: [ "api://51ffc460-61bf-444c-b890-fdff5acb3bb8/.default"] // e.g. [api//middle_tier_api_client_id/.default]
};
export const SpringBrookConfig = {
    uri: "https://apiaryspringbrook.azurewebsites.net", // e.g. http://localhost:5000/api
    resourceScopes: [ "api://8f2c1a9f-677e-49fc-a5f7-92e2d7aa76a6/.default"] // e.g. [api//middle_tier_api_client_id/.default]
};
export const IGraphConfig = {
	uri: "https://apiarygraph.azurewebsites.net", // e.g. http://localhost:5000/api
	resourceScopes: ["api://078bd747-3c33-4000-8ba6-c6e06ebf965f/.default"], // e.g. [api//middle_tier_api_client_id/.default]
}
export const DynamicsConfig = {
    uri: "https://apiaryspringbrook.azurewebsites.net", // e.g. http://localhost:5000/api
    resourceScopes: [ "api://8f2c1a9f-677e-49fc-a5f7-92e2d7aa76a6/.default"] // e.g. [api//middle_tier_api_client_id/.default]
};
export const CityworksConfig = {
	uri: "https://apiarygraph.azurewebsites.net", // e.g. http://localhost:5000/api
	resourceScopes: ["api://078bd747-3c33-4000-8ba6-c6e06ebf965f/.default"], // e.g. [api//middle_tier_api_client_id/.default]
}
/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginApiaryRequest = {
	scopes: [
		"openid",
		"profile",
		"offline_access",
		...ApiaryInterfaceConfig.resourceScopes,
	],
}
export const loginIcommsRequest = {
	scopes: [
		"openid",
		"profile",
		"offline_access",
		...IcommsConfig.resourceScopes,
	],
}
export const loginSpringBrookRequest = {
	scopes: [
		"openid",
		"profile",
		"offline_access",
		...SpringBrookConfig.resourceScopes,
	],
}
export const loginGraphRequest = {
	scopes: [
		"openid",
		"profile",
		"offline_access",
		...IGraphConfig.resourceScopes,
	],
}
export const loginDynamicsRequest = {
	scopes:[
		"openid",
		"profile",
		"offline_access",
		...DynamicsConfig.resourceScopes,
	]
}
export const loginCityworksRequest = {
	scopes:[
		"openid",
		"profile",
		"offline_access",
		...CityworksConfig.resourceScopes,
	]
}
// Add here scopes for access token to be used at the API endpoints.
export const apiaryTokenRequest = {
	scopes: [...ApiaryInterfaceConfig.resourceScopes],
}
export const icommsTokenRequest = {
	scopes: [...IcommsConfig.resourceScopes],
}
export const springBrookTokenRequest = {
	scopes: [...SpringBrookConfig.resourceScopes],
}
export const igraphTokenRequest = {
	scopes: [...IGraphConfig.resourceScopes],
}
export const dynamicsTokenRequest = {
	scopes: [...DynamicsConfig.resourceScopes],
}
export const cityworksTokenRequest = {
	scopes: [...CityworksConfig.resourceScopes],
}
// Add here scopes for silent token request
export const apiarySilentRequest = {
	scopes: ["openid", "profile", ...ApiaryInterfaceConfig.resourceScopes],
}
export const icommsSilentRequest = {
	scopes: ["openid", "profile", ...IcommsConfig.resourceScopes],
}
export const springBrookSilentRequest = {
	scopes: ["openid", "profile", ...SpringBrookConfig.resourceScopes],
}
export const igraphSilentRequest = {
	scopes: ["openid", "profile", ...IGraphConfig.resourceScopes],
}
export const dynamicsSilentRequest = {
	scopes: ["openid", "profile", ...DynamicsConfig.resourceScopes],
}
export const cityworksSilentRequest = {
	scopes: ["openid", "profile", ...CityworksConfig.resourceScopes],
}