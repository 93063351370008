import React, { Component, Fragment } from "react"
import {UncontrolledDropdown, 
	DropdownMenu, DropdownToggle, DropdownItem, Label, Input } from "reactstrap"
import SpringbrookAdmin from './SpringbrookAdmin'
import $ from "jquery"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui"
import LoadingSpinner from "../LoadingSpinner"

/**
 * This is the landing page for admin tools
 */
class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			appToken: "", // apiary node js endpoint token
			icommsToken: "", 
			springBrookToken: "",
			igraphToken:'',
            springbrook:true // display for the springbrook admin tools
			
		}
		this.toggleSpringbrook =this.toggleSpringbrook.bind(this);
		
		
	}
	componentDidMount() {
		this.checkToken();
		(function () {
			$(".connectedSortable").sortable({
				placeholder: "sort-highlight",
				connectWith: ".connectedSortable",
				handle: ".card-header, .nav-tabs",
				forcePlaceholderSize: true,
				zIndex: 999999,
			})
			$(
				".connectedSortable .card-header, .connectedSortable .nav-tabs-custom"
			).css("cursor", "move")

			
		})()
	}
	checkToken() {
	
		this.setState({
			appToken: this.props.auth.apiaryAuthAccessToken,
			icommsToken: this.props.auth.icommsAuthAccessToken,
			springBrookToken: this.props.auth.springBrookAuthAccessToken,
			igraphToken: this.props.auth.igraphAuthAccessToken,
		})
	}
	toggleSpringbrook(){
		this.setState({springbrook:!this.state.springbrook});
	}
	
	render() {
		return (
			<div>
				{this.state.springBrookToken ? 
				<div>
				<div>
					{/* drop down to select/deselct all available options */}
				<UncontrolledDropdown>
						<DropdownToggle className="btn-secondary">
							<i className='fas fa-tasks'></i>
						</DropdownToggle>
						<DropdownMenu className="dropdownDisplay">
							<DropdownItem>
								<Input type="checkbox" id="Springbrook"checked={this.state.springbrook} onClick={e=>this.toggleSpringbrook()} />{'     '}
								<Label for="Springbrook" check>
								{"  "}Springbrok
								</Label>
							</DropdownItem>
							
						</DropdownMenu>
					</UncontrolledDropdown> 
				</div>
				<section className='content'>
					<div className='grid'>
						<div className='row connectedSortable'>
						{this.props.search.meters!==undefined && this.props.search.accounts!==undefined && this.props.search.lots!==undefined ?
							<Fragment>
							{this.state.springbrook ?
							<SpringbrookAdmin  appToken={
									this.state
										.appToken
								}
								springBrookToken={
									this.state
										.springBrookToken
								}
								icommsToken={
									this.state
										.icommsToken
								}
								igraphToken={this.state.igraphToken} {...this.props} 
								togglePayments={this.togglePayments}/>
								: null}
							
							</Fragment> : <LoadingSpinner/>}
						</div>
					</div>
				</section>
				</div>
				: <LoadingSpinner />}
			</div>
		)
	}
}
export default Admin
